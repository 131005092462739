import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'

// Action Types
const types = {
  FETCH_METER_ELECTON_VERIFICATION: 'FETCH_METER_ELECTON_VERIFICATION_V1',
  FETCH_METER_ELECTON_VERIFICATION_SUCCESS:
    'FETCH_METER_ELECTON_VERIFICATION_SUCCESS_V1',
  FETCH_METER_ELECTON_VERIFICATION_ERROR:
    'FETCH_METER_ELECTON_VERIFICATION_ERROR_V1',
  UPDATE_METER_SELECTION_META: 'UPDATE_METER_SELECTION_META_V1',
  RESET_METER_ELECTRON_VERIFICATION: 'RESET_METER_ELECTRON_VERIFICATION_V1',
}

// API
export const API = {
  fetchMeterElectronVerification: ({
    macAddress,
    isLabelCheckOnly,
  }: {
    macAddress: Array<string>
    isLabelCheckOnly: boolean
  }) => {
    const url = `${consoleApiUrl()}/meter/tests/electron-verifications`

    const data = {
      labelCheckTestList: [],
      electronVerificationTestType: 'THREE_DAY_EV',
      macAddresses: [...macAddress],
      resolution: '1min',
    }
    const updatedData =
      isLabelCheckOnly ? data : { ...data, dataQualityTestList: [] }
    return axios
      .post(url, updatedData, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  },
  fetchMeterElectronVerificationId: ({ evId }: { evId: string }) => {
    const url = `${consoleApiUrl()}/meter/tests/electron-verifications/dashboard?evId=${evId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => ({ ...data }))
      .catch(handleAxiosError)
  },
}
// Actions
// eslint-disable-next-line import/no-unused-modules
export const actions = {
  fetchMeterElectronVerification: (params) => ({
    type: types.FETCH_METER_ELECTON_VERIFICATION,
    ...params,
  }),
  updateMeterSelectionMeta: (payload) => ({
    type: types.UPDATE_METER_SELECTION_META,
    payload: { ...payload },
  }),
  resetMeterElectronVerification: () => ({
    type: types.RESET_METER_ELECTRON_VERIFICATION,
  }),
}

// Sagas
function* fetchMeterElectronVerification(params: {
  macAddress: string
  evId: string
}): Generator<any, void, any> {
  const { evId } = params
  try {
    const response = yield call(
      evId ?
        API.fetchMeterElectronVerificationId
      : API.fetchMeterElectronVerification,
      params,
    )
    yield put({
      type: types.FETCH_METER_ELECTON_VERIFICATION_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_METER_ELECTON_VERIFICATION_ERROR, e)
  }
}

// eslint-disable-next-line import/no-unused-modules
export const sagas = [
  takeLatest(
    types.FETCH_METER_ELECTON_VERIFICATION,
    fetchMeterElectronVerification,
  ),
]

// State
const initialState = {
  loading: false,
  error: '',
  meterSelectionMeta: {
    selectedMeters: [],
    searchBy: '',
    siteId: '',
    customerId: '',
    meterMac: '',
    opportunityId: '',
  },
}

// Reducers
function meterElectronVerification(state = initialState, action) {
  switch (action.type) {
    case types.RESET_METER_ELECTRON_VERIFICATION:
      return { ...state, data: null, error: '', loading: false }

    case types.FETCH_METER_ELECTON_VERIFICATION:
      return { ...state, error: '', loading: true }

    case types.FETCH_METER_ELECTON_VERIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: '',
        loading: false,
      }

    case types.FETCH_METER_ELECTON_VERIFICATION_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case types.UPDATE_METER_SELECTION_META:
      return {
        ...state,
        meterSelectionMeta: {
          ...state.meterSelectionMeta,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

// Selectors
export const selectMeterElectronVerification = (state) =>
  state.entities?.meterElectronVerificationV1

export const selectMeterSelectionMeta = (state) =>
  state.entities?.meterElectronVerificationV1.meterSelectionMeta

export default meterElectronVerification
