import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'

import MessageFlasher from '../components/MessageFlasher'
import Messages from '../components/Messages'
import { rollbar } from '../components/RollbarProvider'
import { STORAGE_LOGIN_TOKEN } from '../constants'
import HeaderContainer from '../containers/HeaderContainer'
import { selectors as authSelectors } from '../ducks/auth'
import { currentUserId, currentUsername } from '../reducers/initialState'
import type { FTRouteProps, FTWithRouter } from '../types'

type FTProps = FTWithRouter & FTRouteProps

class PrivateRoute extends PureComponent<FTProps> {
  static defaultProps = {
    nextState: null,
  }

  componentDidMount() {
    const isAuthenticated = !!localStorage.getItem(STORAGE_LOGIN_TOKEN)

    if (isAuthenticated) {
      rollbar.configure({
        payload: {
          ...rollbar.options.payload,
          person: {
            id: currentUserId(),
            email: currentUsername() as string | undefined,
          },
        },
      })
    }
  }
  renderComponent = (props) => {
    const {
      component: PrivateComponent,
      isLoadingPermissions,
      location,
    } = this.props
    const { pathname = '' } = location || {}

    if (isLoadingPermissions) {
      return <div />
    }

    return localStorage.getItem(STORAGE_LOGIN_TOKEN) ?
        <div className='PrivateRoute'>
          <HeaderContainer pathname={pathname} />
          <div>
            <MessageFlasher />
            <PrivateComponent {...props} />
            <Messages />
          </div>
        </div>
      : <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
            },
          }}
        />
  }

  render() {
    const { component: _, nextState: __, ...rest } = this.props
    window.scrollTo(0, 0)
    return <Route {...rest} render={this.renderComponent} />
  }
}

const mapStateToProps = (state) => ({
  isLoadingPermissions: authSelectors.isLoadingPermissions(state),
})

export default withRouter(connect(mapStateToProps)(PrivateRoute))
