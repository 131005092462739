import Tippy from '@tippyjs/react'
import styled from 'styled-components'

import StyledLink from '../../../../../components/StyledLink'

const CellWithToolTip = styled.p<{ maxWidth?: number }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  max-width: ${(props) => props.maxWidth}px;
  width: 240px;
`

const CellComponentWithLink = ({
  original,
  value,
  column: { id, maxWidth },
}: Record<string, any>) => {
  const renderedValue = value ?? '-'
  switch (id) {
    case 'customerName':
      return (
        <td data-testid={id}>
          <Tippy content={renderedValue} delay={500}>
            <CellWithToolTip maxWidth={maxWidth}>
              <StyledLink
                href={`/account-management/customers/${original.customerId}`}
                external
              >
                {renderedValue}
              </StyledLink>
            </CellWithToolTip>
          </Tippy>
        </td>
      )
    case 'siteName':
      return (
        <td data-testid={id}>
          <Tippy content={renderedValue} delay={500}>
            <CellWithToolTip maxWidth={maxWidth}>
              <StyledLink
                href={`/account-management/sites/${original.siteId}`}
                external
              >
                {renderedValue}
              </StyledLink>
            </CellWithToolTip>
          </Tippy>
        </td>
      )
    case 'name':
      return (
        <td data-testid={id}>
          <Tippy content={renderedValue} delay={500}>
            <CellWithToolTip maxWidth={maxWidth}>
              <StyledLink
                href={`/account-management/meters/${original.id}`}
                external
              >
                {renderedValue}
              </StyledLink>
            </CellWithToolTip>
          </Tippy>
        </td>
      )
    default:
      return <td data-testid={id}>{renderedValue}</td>
  }
}

export default CellComponentWithLink
