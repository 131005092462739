import moment, { Moment } from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import MonthPicker from '../../../../components/MonthPicker'
import { energyStarSubmissionsEntity } from '../../../../ducks/energyStarIntegration'
import { getDefaultMonth } from '../../helpers/utils'
import { MonthPickerWrapperStyles } from '../../styles'

const RenderMonthPicker = ({ onSubmit }: { onSubmit?: () => void }) => {
  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)
  const activeMonthFromQuery = queryParams.get('activeMonth')

  const [showMonthPickerDropDown, setShowMonthPickerDropDown] = useState(false)
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    getDefaultMonth(activeMonthFromQuery),
  )
  const energyStarEntity = useSelector(energyStarSubmissionsEntity)

  const handleOnSubmit = (month: Moment) => {
    if (onSubmit) onSubmit()

    const newMonth = month.format('YYYY-MM')
    queryParams.set('activeMonth', newMonth)
    history.push({ search: queryParams.toString() })

    setSelectedYearMonth(newMonth)
    setShowMonthPickerDropDown(false)
  }

  return (
    <MonthPickerWrapperStyles
      isDisabled={energyStarEntity?.meta?.suppressLoading}
    >
      <MonthPicker
        activeMonth={moment(selectedYearMonth, 'YYYY-MM')}
        showMonthPickerDropDown={showMonthPickerDropDown}
        setShowMonthPickerDropDown={setShowMonthPickerDropDown}
        onCancel={() => {
          setShowMonthPickerDropDown(false)
        }}
        onSubmit={handleOnSubmit}
      />
    </MonthPickerWrapperStyles>
  )
}

export default RenderMonthPicker
