import { useCallback } from 'react'

import StyledCheckbox from '../../../../../components/Checkbox'
import StyledRadioButton from '../../../../../components/RadioButton'
import RedaptiveReactTable from '../../../../../components/RedaptiveReactTable'
import CellComponent from '../CellComponent/CellComponent'
import CellComponentWithLink from '../CellComponentWithLink/CellComponentWithLink'

interface MeterListTableProps {
  data: any
  setPageSize?: any
  pageSize?: number
  setPageNumber?: any
  pageNumber?: number
  totalResults?: number
  isResultScreen: boolean
  handleCheckboxClick?: any
  selectedMeters?: any
  isSelectedMeter?: any
  isSelectedMeterOnResult?: any
  meterStatusLoading?: boolean
  dataAvailableLoading?: boolean
}

const MeterListTable = (props: MeterListTableProps) => {
  const {
    data,
    setPageSize,
    pageSize,
    setPageNumber,
    pageNumber,
    totalResults,
    isResultScreen = false,
    handleCheckboxClick,
    selectedMeters,
    isSelectedMeter,
    isSelectedMeterOnResult,
    meterStatusLoading,
    dataAvailableLoading,
  } = props
  const RenderSelectionCell = useCallback(
    ({ original }) => (
      <td data-testid='enable'>
        <StyledCheckbox
          checked={isSelectedMeter(selectedMeters, original)}
          onClick={() => handleCheckboxClick(original)}
          aria-label='Select Meter'
        />
      </td>
    ),
    [handleCheckboxClick, selectedMeters],
  )

  const RenderResultSelectionCell = useCallback(
    ({ original }) => (
      <td data-testid='enable'>
        <StyledRadioButton
          checked={isSelectedMeterOnResult(original)}
          onClick={() => handleCheckboxClick(original)}
          aria-label='Select Meter'
        />
      </td>
    ),
    [handleCheckboxClick, selectedMeters],
  )
  if (!isResultScreen) {
    const columns = [
      {
        accessor: 'created',
        Cell: RenderSelectionCell,
        Header: 'Enable',
        disableFilters: true,
        maxWidth: 100,
      },
      {
        accessor: 'name',
        Cell: CellComponent,
        Header: 'Meter MAC',
        id: 'name',
        disableFilters: true,
        maxWidth: 160,
        minWidth: 160,
      },
      {
        accessor: 'siteName',
        Cell: CellComponent,
        Header: 'Site',
        id: 'siteName',
        disableFilters: true,
        maxWidth: 250,
        minWidth: 250,
      },
      {
        accessor: 'customerName',
        Cell: CellComponent,
        Header: 'Customer',
        id: 'customerName',
        disableFilters: true,
        maxWidth: 150,
        minWidth: 150,
      },
      {
        accessor: 'onlineStatus',
        Cell: meterStatusLoading ? 'Loading...' : CellComponent,
        Header: 'Online Status',
        id: 'onlineStatus',
        disableFilters: true,
        maxWidth: 200,
      },
      {
        accessor: 'firstReportDate',
        Cell: CellComponent,
        Header: 'First Report Date',
        id: 'firstReportDate',
        disableFilters: true,
        maxWidth: 300,
        minWidth: 300,
      },
      {
        accessor: 'mostRecentConfigurationDate',
        Cell: CellComponent,
        Header: 'Last Config Date',
        id: 'mostRecentConfigurationDate',
        disableFilters: true,
        maxWidth: 300,
        minWidth: 300,
      },
      {
        accessor: 'verified',
        Cell: CellComponent,
        Header: 'Electron Verified',
        id: 'verified',
        disableFilters: true,
        maxWidth: 160,
        minWidth: 160,
        width: 160,
      },
      {
        accessor: 'dataAvailable',
        Cell: dataAvailableLoading ? 'Loading...' : CellComponent,
        Header: 'Data Available',
        id: 'dataAvailable',
        disableFilters: true,
        maxWidth: 160,
        minWidth: 160,
        width: 160,
      },
      {
        accessor: 'name',
        Cell: CellComponent,
        Header: 'History',
        id: 'history',
        disableFilters: true,
        maxWidth: 160,
        minWidth: 160,
        width: 160,
      },
    ]
    return (
      <RedaptiveReactTable
        className='-striped -highlight'
        data={data}
        columns={columns}
        filterable={false}
        sortable={false}
        resizable={false}
        setPageSize={setPageSize}
        pageSize={pageSize}
        showCustomPagination
        gotoPage={(page: number) => setPageNumber(page)}
        pageIndex={(pageNumber || 1) - 1}
        totalResults={totalResults}
        paginationSize={[10]}
        hideAllPagination
      />
    )
  }
  const columns = [
    {
      accessor: 'created',
      Cell: RenderResultSelectionCell,
      Header: 'Enable',
      disableFilters: true,
      maxWidth: 100,
    },
    {
      accessor: 'name',
      Cell: CellComponentWithLink,
      Header: 'Meter MAC',
      id: 'name',
      disableFilters: true,
      maxWidth: 160,
      minWidth: 160,
    },
    {
      accessor: 'customerName',
      Cell: CellComponentWithLink,
      Header: 'Customer',
      id: 'customerName',
      disableFilters: true,
      maxWidth: 150,
      minWidth: 150,
    },
    {
      accessor: 'siteName',
      Cell: CellComponentWithLink,
      Header: 'Site',
      id: 'siteName',
      disableFilters: true,
      maxWidth: 250,
      minWidth: 250,
    },

    {
      accessor: 'opportunityId',
      Cell: CellComponent,
      Header: 'Opportunity ID',
      id: 'opportunityId',
      disableFilters: true,
      maxWidth: 150,
      minWidth: 150,
    },
    {
      accessor: 'meterDetail',
      Cell: CellComponent,
      Header: 'Panel Name',
      id: 'panelName',
      disableFilters: true,
      maxWidth: 150,
      minWidth: 150,
    },
    {
      accessor: 'labelCheckResults',
      Cell: CellComponent,
      Header: 'Label Check',
      id: 'labelCheckResults',
      disableFilters: true,
      maxWidth: 120,
      minWidth: 120,
    },
    {
      accessor: 'dataQualityTestResults',
      Cell: CellComponent,
      Header: 'Data Check',
      id: 'dataQualityTestResults',
      disableFilters: true,
      maxWidth: 120,
      minWidth: 120,
    },
    {
      accessor: 'onlineStatus',
      Cell: meterStatusLoading ? 'Loading...' : CellComponent,
      Header: 'Online Status',
      id: 'onlineStatus',
      disableFilters: true,
      maxWidth: 200,
    },
    {
      accessor: 'meterDetail',
      Cell: CellComponent,
      Header: 'Panel Config',
      id: 'panelConfig',
      disableFilters: true,
      maxWidth: 250,
      minWidth: 250,
    },
    {
      accessor: 'lastConfigDate',
      Cell: CellComponent,
      Header: 'Last Config Date',
      id: 'lastConfigDate',
      disableFilters: true,
      maxWidth: 280,
      minWidth: 280,
    },
    {
      accessor: 'lastMeasurementDate',
      Cell: meterStatusLoading ? 'Loading...' : CellComponent,
      Header: 'Last Measurement Date',
      id: 'lastMeasurementDate',
      disableFilters: true,
      maxWidth: 280,
      minWidth: 280,
    },
    {
      accessor: 'name',
      Cell: CellComponent,
      Header: 'View History',
      id: 'history',
      disableFilters: true,
      maxWidth: 160,
      minWidth: 160,
      width: 160,
    },
  ]
  return (
    <RedaptiveReactTable
      className='-striped -highlight'
      data={data}
      columns={columns}
      filterable={false}
      sortable={false}
      resizable={false}
      hideAllPagination
    />
  )
}
export default MeterListTable
