import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions as customerActions,
  selectCustomerListEntity,
} from '../../ducks/customers'
import { actions as siteActions, selectSiteListEntity } from '../../ducks/sites'
import Button2 from '../Button2'
import {
  SelectContainerStyled,
  StyledLabel,
  StyledSelect,
  ButtonContainerStyled,
} from './styles'

export default function CustomerSiteIdSelector({
  disabled,
  getSiteId,
  onReset,
  id,
}: {
  disabled?: boolean
  getSiteId: (siteId: string) => void
  onReset: () => void
  id: string
}) {
  const [customerId, setCustomerId] = useState('')
  const [siteId, setSiteId] = useState(id)

  const dispatch = useDispatch()

  const customerListEntity = useSelector(selectCustomerListEntity)
  const siteListEntity = useSelector(selectSiteListEntity)

  const { items: customerItems, meta: customerMeta } = customerListEntity || {}
  const { items: siteItems, meta: siteMeta } = siteListEntity || {}

  useEffect(() => {
    dispatch(
      customerActions.fetchAllCustomers({
        pageSize: 10 ** 4,
        orderBy: { field: 'name', sort: 'ASC' },
        pageNumber: 1,
      }),
    )
  }, [])

  useEffect(() => {
    if (id) {
      const customerId = siteItems[0]?.customerId
      setCustomerId(customerId || '')
    }
  }, [id, siteItems])

  const onSetCustomerId = (value: string) => {
    setCustomerId(value)
    dispatch(
      siteActions.fetchAllSites({
        customerId: value,
        pageSize: 10 ** 4,
        pageNumber: 1,
      }),
    )
  }

  const customerOptions = customerItems.map((customer) => ({
    id: customer.id,
    name: customer.validName,
  }))
  const siteOptions = siteItems.map((site) => ({
    id: site.id,
    name: site.validName,
  }))
  const selectedCustomerItem = customerOptions.find(
    (customer) => customer.id === customerId,
  )

  const selectedSiteItem = siteOptions.find((site) => site.id === siteId)

  return (
    <div>
      <SelectContainerStyled>
        <StyledLabel>Customer</StyledLabel>
        <StyledSelect
          items={customerOptions}
          selectedItem={selectedCustomerItem}
          disabled={disabled || customerMeta.loading}
          notSetLabelText={
            customerMeta.loading ? 'Loading...' : 'Select Customer'
          }
          updateValue={({ value }) => onSetCustomerId(value)}
        />
      </SelectContainerStyled>
      <SelectContainerStyled>
        <StyledLabel>Site</StyledLabel>
        <StyledSelect
          items={siteOptions}
          selectedItem={selectedSiteItem}
          disabled={!customerId || siteMeta?.loading || disabled}
          notSetLabelText={siteMeta.loading ? 'Loading...' : 'Select Site'}
          updateValue={({ value }) => {
            setSiteId(value)
          }}
        />
      </SelectContainerStyled>
      <ButtonContainerStyled>
        <Button2
          type='redaptiveSecondary'
          disabled={false}
          onClick={() => {
            setCustomerId('')
            setSiteId('')
            onReset()
          }}
        >
          RESET
        </Button2>
        <Button2
          type='redaptivePrimary'
          disabled={!siteId || disabled}
          onClick={() => {
            getSiteId(siteId)
          }}
        >
          SUBMIT
        </Button2>
      </ButtonContainerStyled>
    </div>
  )
}
