import Tippy from '@tippyjs/react'
import InfoCircleOutline from '../../components/Icons/svg/InfoCircleOutline'
import { utils } from '../../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'

import { TooltipIcon } from './styles'

export const ctTypeHeader = (
  <span>
    CT Size
    <Tippy content='RC stands for Rogowski Coil' delay={100}>
      <TooltipIcon>
        <InfoCircleOutline />
      </TooltipIcon>
    </Tippy>
  </span>
)

export const defaultSwitchColumns = [
  {
    accessor: 'breakerNumber',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Breaker',
    width: 60,
  },
  {
    accessor: 'breakerType',
    Cell: ({ value }: { value: string }) =>
      utils.breakerTypes[value as keyof typeof utils.breakerTypes] ||
      value ||
      'Invalid',
    Header: 'Type/Name',
  },
  {
    accessor: 'phase',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Phase',
    width: 60,
  },
  {
    accessor: 'ctSize',
    Cell: ({ value }: { value: string }) => (value ? ctTypeEnum[value] : '-'),
    Header: ctTypeHeader,
    width: 90,
  },

  {
    accessor: 'meterChannel',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Meter Channel',
    width: 100,
  },
  {
    accessor: 'phaseGroup',
    Cell: ({ value }: { value: string }) => value || '-',
    Header: 'Phase Group',
  },
]

export const ctTypeEnum = {
  '15': '15A',
  '30': '30A',
  '50': '50A',
  '80': '80A',
  '100': '100A',
  '120': '120A',
  '150': '150A',
  '300': '300A',
  '600': '600A',
  '1200': '1200A',
  '3000': '3000A',
  '300 RC': '300A RC',
  '600 RC': '600A RC',
}
