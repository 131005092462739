export const breadcrumbs = [
  {
    href: '/reports',
    text: 'Tools & Reports',
  },
  {
    href: '/reports/hydroint-meter-tools',
    text: 'Water Meter Tools',
  },
]

export const commonFields = [
  {
    id: 'externalAccountId',
    label: 'Account ID',
    minWidth: 150,
  },
  {
    id: 'externalSiteId',
    label: 'Site ID',
    minWidth: 150,
  },
  {
    id: 'externalMeterId',
    label: 'Meter ID',
  },
  {
    id: 'externalMeterName',
    label: 'Meter Name',
  },
  {
    id: 'externalCompany',
    label: 'Company',
  },
  {
    id: 'externalSiteName',
    label: 'Site Name',
  },
  {
    id: 'externalSiteDescription',
    label: 'Site Description',
  },
  {
    id: 'externalAddress',
    label: 'Address',
  },
  {
    id: 'externalCity',
    label: 'City',
  },
  {
    id: 'externalState',
    label: 'State',
  },
  {
    id: 'externalZip',
    label: 'Zip',
  },
  {
    id: 'externalCountry',
    label: 'Country',
  },
  {
    id: 'externalTimeZone',
    label: 'Time Zone',
  },
]

export const commonDateFields = [
  {
    id: 'externalEarliestCalibrationDate',
    label: 'Earliest Calibrated Date',
    minWidth: 210,
  },
  {
    id: 'externalLastCalibrationDate',
    label: 'Last Calibrated Date',
    minWidth: 210,
  },
  {
    id: 'externalLastCommunicationDate',
    label: 'Last Communication Date',
    minWidth: 210,
  },
  {
    id: 'externalLastUsageDate',
    label: 'Last Usage Date',
    minWidth: 210,
  },
  {
    id: 'externalCreatedDate',
    label: 'Created Date',
    minWidth: 210,
  },
]

const customerSiteFields = [
  {
    id: 'customerName',
    label: 'Grand Central Customer',
  },
  {
    id: 'siteName',
    label: 'Grand Central Site',
  },
]

const siteTimezone = [
  {
    id: 'siteTimezone',
    label: 'Grand Central Time Zone',
  },
]

const extraCalibrationField = [
  {
    id: 'externalCalibrationLevelName',
    label: 'Calibration Level',
  },
]

export const defaultSiteReadinessInstallFields = [
  ...commonFields,
  ...extraCalibrationField,
  ...commonDateFields,
  {
    id: 'lastDataIngestionDate',
    label: 'Grand Central Last Data Ingestion Date',
    minWidth: 260,
  },
  ...customerSiteFields,
]

export const defaultExternalMeterDetailFields = [
  ...commonFields,
  ...siteTimezone,
  ...extraCalibrationField,
  ...commonDateFields,
  ...customerSiteFields,
]

const getColumnHeaders = (fields) =>
  fields.map((item) => ({
    fieldName: item.id,
    label: item.label,
    minWidth: item.minWidth || 150,
    isVisible: true,
  }))

export const defaultSiteReadinessInstallHeaders = getColumnHeaders(
  defaultSiteReadinessInstallFields,
)

export const defaultMeterDetailHeaders = getColumnHeaders(
  defaultExternalMeterDetailFields,
)

export const paginationSize = [100, 50, 20]
