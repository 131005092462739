import { useState } from 'react'
import { breadcrumbs } from './constants'
import SiteReadiness from './tabs/SiteReadiness'
import MeterDetail from './tabs/MeterDetail'
import Breadcrumbs from '../../../components/Breadcrumbs'
import TabPane from '../../../components/Tabs/TabPane'
import { Wrapper } from './styles'
import { isVariantActive } from '../../../utils'

const HydropointMeterTools = () => {
  const [selectedView, setSelctedView] = useState('customerAndSite')
  const [id, setId] = useState('')

  const meterTab = {
    tab: 'meter-detail',
    text: 'Meter Detail',
    render: () => (
      <MeterDetail
        selectedView={selectedView}
        setSelectedView={setSelctedView}
        id={id}
        setId={setId}
      />
    ),
  }
  const tabs = [
    {
      tab: 'site-readiness',
      text: 'Site Readiness',
      render: () => <SiteReadiness />,
    },
    meterTab,
  ]

  return (
    <Wrapper>
      <Breadcrumbs items={breadcrumbs} />
      <TabPane tabs={tabs} />
    </Wrapper>
  )
}

export default HydropointMeterTools
