import styled from 'styled-components'

export const TooltipIcon = styled.span`
  svg {
    position: relative;
    top: 3px;
    left: 2px;
    cursor: pointer;
  }
`
