import { css } from 'styled-components'

export type FTThemeName = 'redaptive' | 'redaptive2'
export const getThemeFromPathname = (pathname: string): FTThemeName =>
  (
    [
      'version',
      'variants',
      'tools',
      'swagger',
      'authorization-playground',
      'not-authorized',
      'login',
      'help',
      'forgot',
      'account-management',
      'reports',
    ].some((pathFragment) => pathname.includes(pathFragment))
  ) ?
    'redaptive'
  : 'redaptive2'
export const colors: Record<string, string> = {
  redaptiveBlue: '#0194d7',
  blue15: '#337AB7',
  blue14: '#DADCE0',
  blue13: '#959697',
  blue12: '#464A54',
  blue11: 'rgba(62, 92, 170, 0.16)',
  blue10: 'rgba(62, 92, 170, 0.24)',
  blue9: 'rgba(72, 93, 160, 0.08)',
  blue8: 'rgba(72, 93, 160, 0.16)',
  blue7: '#35A0CD',
  blue6: '#2D417D',
  blue5: '#162447',
  blue4: '#4A90E2',
  blue3: '#6C6D6E',
  blue2: '#3E5CAA',
  blue: '#485DA0',
  green4: '#52AB65',
  green3: '#7BCC76',
  green2: '#A9B988',
  green5: '#4bb939',
  green: '#9DBD79',
  orange3: '#F2AE00',
  orange2: '#F2A91C',
  orange4: '#F3A91C',
  orange: '#E7AC4C',
  purple: '#7B61FF',
  red6: '#C70D08',
  red5: '#B00703',
  red4: 'rgba(194, 83, 83, 0.16)',
  red3: '#BD7979',
  red2: '#C25353',
  red: '#C02F2F',
  gray4: '#FAFAFA',
  gray7: '#F5F5F5',
  gray6: '#dadada',
  gray5: '#F6F6F6',
  black: '#000000',
  grey: '#A7A7A8',
  gray3: '#E0E0E0',
  gray: '#7F7F7F',
  gray2: '#4A4A4A',
  gray8: '#c7c7c7',
  gray9: '#F7F7F7',
  white: '#ffffff',
  silver: '#cccccc',
  linkBlue: '#35729e',
}
export const Redaptive2PageStyles = css`
  * {
    box-sizing: border-box;
  }

  font-family: 'Public Sans', sans-serif;

  a {
    color: ${colors.blue};

    &:hover {
      color: ${colors.blue4};
    }
  }
`
