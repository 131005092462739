import RedaptiveReactTable7 from '../../../../components/RedaptiveReactTable7'
import { paginationSize } from '../constants'
import { TableWrapperStyled, Title } from '../styles'
import Loader from './Loader'
import Tippy from '@tippyjs/react'
import { Cell } from '../../../MeterInstallGroupedSubmissions/styles'
import { fieldMappingsMeterDetail } from '../tabs/columnSettings'
import { useEffect } from 'react'
import { useExternalMeterDetails } from '../../../../queries/hydropoint'

export default function Table({ id, flow }) {
  const { mutate, data, isLoading, reset, error } = useExternalMeterDetails()
  useEffect(() => {
    if (id) {
      mutate({ id, flow })
    } else {
      reset()
    }
  }, [id, flow])
  const getColumnCell = (cellProps) => {
    const { value } = cellProps
    return (
      (
        <Tippy content={value}>
          <Cell maxWidth={200}>{value}</Cell>
        </Tippy>
      ) || '-'
    )
  }

  const columns = fieldMappingsMeterDetail.map((item) => ({
    accessor: item.fieldName,
    Cell: getColumnCell,
    Header: item.label,
    minWidth: item.minWidth || 150,
    fieldName: item.fieldName,
  }))

  if (!id) return null

  return (
    <>
      <TableWrapperStyled>
        <Title>
          <div>Meter Details Table</div>
        </Title>
        <RedaptiveReactTable7
          columns={columns}
          data={data || []}
          globalFilterable={false}
          filterable
          disableSortRemove
          maxLockableColumns={3}
          enableColumnHiding
          enableColumnSettings={false}
          initialHiddenColumns={[]}
          initialLockedColumns={[]}
          alwaysLockedColumns={[]}
          initialPageSize={20}
          paginationSize={paginationSize}
          enablePagination
          enablePageSizeSelector
          hideDropdownPagination
          hideSeparator
          loading={isLoading}
          noDataFoundText={
            (error as Error)?.message ||
            'No data found. Change the filters and try again.'
          }
        />
      </TableWrapperStyled>
      {isLoading && <Loader />}
    </>
  )
}
