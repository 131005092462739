import {
  defaultMeterDetailHeaders,
  defaultSiteReadinessInstallHeaders,
} from '../constants'

export const alwaysLockedColumns = ['externalAccountId', 'externalSiteId']
const getMappings = localStorage.getItem('siteReadinessFieldMappings')
const mappingsFromStorage = getMappings ? JSON.parse(getMappings) : null
export const fieldMappings =
  mappingsFromStorage || defaultSiteReadinessInstallHeaders
export const fieldMappingsMeterDetail = defaultMeterDetailHeaders

export const initialHiddenColumns = fieldMappings
  ?.filter((column) => column?.isVisible !== true)
  .map((column) => column.fieldName)

export const initialLockedColumns = fieldMappings
  ?.filter((column) => column.isLocked === true)
  .map((column) => column.fieldName)

export const handleColumnSettingsClose = ({
  columns: columnsNew,
  lockedColumns: lockedColumnsNew,
  columnsVisibleOnTable,
}) => {
  const fieldMappingsNew = columnsNew.map((columnEntry, index) => {
    const entry = defaultSiteReadinessInstallHeaders.find(
      (item) => item.fieldName === columnEntry,
    )
    return {
      fieldName: columnEntry,
      isLocked: lockedColumnsNew.includes(columnEntry),
      isVisible: columnsVisibleOnTable.includes(columnEntry),
      sequenceNumber: index,
      label: entry?.label,
      minWidth: entry?.minWidth,
    }
  })
  localStorage.setItem(
    'siteReadinessFieldMappings',
    JSON.stringify(fieldMappingsNew),
  )
}
