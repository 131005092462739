import Button2 from '../../../../components/Button2'
import { ButtonContainerStyled } from '../../FullEV/styles'
import { useState } from 'react'
import { InputLabel, Input, InputWrapper } from '../styles'

export default function LabelInput({
  disabled,
  label,
  getId,
  placeholder,
  id,
}) {
  const [value, setValue] = useState(id)
  const onChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <>
      <InputWrapper>
        <InputLabel>{label}</InputLabel>
        <Input
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
        />
      </InputWrapper>
      <ButtonContainerStyled>
        <Button2
          type='redaptiveSecondary'
          onClick={() => {
            getId('')
            setValue('')
          }}
        >
          RESET
        </Button2>
        <Button2
          type='redaptivePrimary'
          disabled={disabled || value.length !== 5}
          onClick={() => {
            getId(value)
          }}
        >
          SUBMIT
        </Button2>
      </ButtonContainerStyled>
    </>
  )
}
