export default {
  FIFTEEN_AMP: {
    text: '10mm',
    subText: '15A',
  },
  THIRTY_AMP: {
    text: '10mm',
    subText: '30A',
  },
  FIFTY_AMP: {
    id: 'FIFTY_AMP',
    text: '16mm',
    subText: '50A',
  },
  HUNDRED_AMP: {
    id: 'HUNDRED_AMP',
    text: '24mm',
    subText: '100A',
  },
  ONE_FIFTY_AMP: {
    id: 'ONE_FIFTY_AMP',
    text: '36mm',
    subText: '150A',
  },
  THREE_HUNDRED_AMP: {
    id: 'THREE_HUNDRED_AMP',
    text: '36mm',
    subText: '300A',
  },
  SIX_HUNDRED_AMP: {
    id: 'SIX_HUNDRED_AMP',
    text: '36mm',
    subText: '600A',
  },
  THREE_HUNDRED_AMP_RC: {
    id: 'THREE_HUNDRED_AMP_RC',
    text: '-',
    subText: '300A RC',
  },
  SIX_HUNDRED_AMP_RC: {
    id: 'SIX_HUNDRED_AMP_RC',
    text: '-',
    subText: '600A RC',
  },
}
