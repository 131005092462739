import styled from 'styled-components'
import { colors } from '../../utils/themes'

export const Link = styled.a`
  color: ${colors.linkBlue};
`

export const BlackText = styled.span`
  color: ${colors.black};
`
