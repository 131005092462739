export const defaultMeterInstallHeaders = [
  {
    id: 'opportunityId',
    label: 'Opportunity ID',
  },
  {
    id: 'modified',
    label: 'Date',
  },
  {
    id: 'macAddress',
    label: 'Meter MAC',
  },
  {
    id: 'panelNames',
    label: 'Panel Names',
  },
  {
    id: 'modifiedBy',
    label: 'Submitted by',
  },
  {
    id: 'customerName',
    label: 'Customer',
  },
  {
    id: 'isAssignedToSite',
    label: 'Assigned to Site',
  },
  {
    id: 'siteLocation',
    label: 'Location',
  },
  {
    id: 'configAcceptedDate',
    label: 'Config Accepted',
  },
  {
    id: 'cellRouterInstalled',
    label: 'Cell Router Installed',
  },
  {
    id: 'evVerified',
    label: 'EV Verified',
  },
  {
    id: 'preConfigTestStatus',
    label: 'Pre-Config Tests',
  },
  {
    id: 'postConfigTestStatus',
    label: 'Post-Config Tests',
  },
]
export const defaultMeterInstallWidths = {
  opportunityId: {
    minWidth: 150,
    maxWidth: 120,
  },
  modified: {
    minWidth: 235,
    maxWidth: 235,
  },
  macAddress: {
    minWidth: 110,
    maxWidth: 110,
  },
  panelNames: {
    minWidth: 120,
    maxWidth: 130,
  },
  modifiedBy: {
    minWidth: 210,
    maxWidth: 210,
  },
  customerName: {
    minWidth: 140,
    maxWidth: 160,
  },
  isAssignedToSite: {
    minWidth: 120,
    maxWidth: 130,
  },
  siteLocation: {
    minWidth: 160,
    maxWidth: 180,
  },
  configAcceptedDate: {
    minWidth: 225,
    maxWidth: 225,
  },
  cellRouterInstalled: {
    minWidth: 100,
    maxWidth: 100,
  },
  evVerified: {
    minWidth: 80,
    maxWidth: 100,
  },
  preConfigTestStatus: {
    minWidth: 80,
    maxWidth: 120,
  },
  postConfigTestStatus: {
    minWidth: 100,
    maxWidth: 120,
  },
}
export const submissionPhotoObjectTypeMapping = {
  PANEL: 'Panel Feed',
  PPE_EVIDENCE: 'PPE Evidence',
  SWITCH: 'Switch',
  CT: 'Finished Install',
  SAFETY_WARNING: 'Lockout Tagout Evidence',
  WRAP_UP: 'Panel Schedule',
  LEFT_OVER_EQUIPMENT: 'Left Over Equipment Photos',
}
// Ampere to mm mapping of CT Sizes
export const ctSizeMapping = Object.freeze({
  15: {
    id: 'FIFTEEN_AMP',
    text: '10mm',
    subText: '15A',
  },
  30: {
    id: 'THIRTY_AMP',
    text: '10mm',
    subText: '30A',
  },
  50: {
    id: 'FIFTY_AMP',
    text: '16mm',
    subText: '50A',
  },
  100: {
    id: 'HUNDRED_AMP',
    text: '24mm',
    subText: '100A',
  },
  150: {
    id: 'ONE_FIFTY_AMP',
    text: '36mm',
    subText: '150A',
  },
  300: {
    id: 'THREE_HUNDRED_AMP',
    text: '36mm',
    subText: '300A',
  },
  600: {
    id: 'SIX_HUNDRED_AMP',
    text: '36mm',
    subText: '600A',
  },
  '300 RC': {
    id: 'THREE_HUNDRED_RC',
    text: '-',
    subText: '300A RC',
  },
  '600 RC': {
    id: 'SIX_HUNDRED_RC',
    text: '-',
    subText: '600A RC',
  },
})
