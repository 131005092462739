import isEqual from 'lodash.isequal'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import Breadcrumbs from '../../components/Breadcrumbs'
import Description from '../../components/Description'
import { defaultSiteIds } from '../../components/SiteDetail/SiteDetail'
import SiteForm from '../../components/SiteForm'
import Title from '../../components/Title'
import * as status from '../../constants/status'
import {
  actions as customerActions,
  naturallySortCustomers,
  selectCustomerListEntity,
} from '../../ducks/customers'
import { actions as messageActions } from '../../ducks/messageFlasher'
import {
  actions as siteActions,
  selectSiteExternalResources,
} from '../../ducks/sites'
import type { FTContract, FTHistory, FTSiteExternalResource } from '../../types'

const FormWidth = styled.div`
  width: 570px;
`
const Styles = styled.div`
  b {
    font-weight: 500;
  }
`
type FTProps = {
  error: Record<string, any>
  loading: boolean
  breadcrumbsLoading?: boolean
  history: FTHistory
  site: Record<string, any> | null | undefined
  siteExternalResources: FTSiteExternalResource
  customerId: string
  customers: Array<Record<string, any>>
  customer: Record<string, any> | null | undefined
  siteId: string | null | undefined
  actions: Record<string, any>
  match: {
    params: Record<string, any>
  }
  location: {
    pathname: string
  }
  createdSiteId: string | null | undefined
  editedSiteId: string | null | undefined
  newOpportunity: Record<string, any>
  contractUpdated: boolean
  isSearchingOpportunity: boolean
  contractFetchError: string
  newOpportunityIndex: number
}
type FTState = {
  initializing: boolean
}

class SiteFormPage extends Component<FTProps, FTState> {
  static defaultProps = {
    breadcrumbsLoading: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      initializing: true,
    }
  }

  componentDidMount() {
    const { siteId, actions } = this.props

    if (siteId) {
      actions.fetchSite({
        siteId,
      })
      actions.fetchSiteExternalResources({
        siteId,
      })
    }

    actions.fetchAllCustomers({
      pageSize: 1000,
    })
    this.setState((prev) => ({ ...prev, initializing: false }))
  }

  componentDidUpdate({
    createdSiteId: prevNewId,
    editedSiteId: prevEditId,
    error,
  }) {
    const {
      createdSiteId: nextNewId,
      editedSiteId: nextEditId,
      history,
    } = this.props

    if (!prevNewId && nextNewId) {
      history.push(`/account-management/sites/${nextNewId}`)
      return
    }

    if (!prevEditId && nextEditId && !error) {
      history.push(`/account-management/sites/${nextEditId}`)
    }
  }

  componentWillUnmount() {
    this.props.actions.clearSiteForm()
  }

  goBack = () => {
    const { history } = this.props

    if (history.length > 1) {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  handleSubmit = (fullBody) => {
    const {
      site,
      actions: { updateSite, addSite, updateSiteExternalResource },
    } = this.props
    const { contracts = [], resourceProvider, externalId } = fullBody
    const contractsFiltered = []
    contracts.forEach((contract: FTContract) => {
      // Only accepts contracts with an opportunityID.
      if (contract.opportunityId) {
        contractsFiltered.push({
          ...contract,
          // Only includes the id field if an ID exists.
          id: contract.id || undefined,
          // HVAC only fields.
          contractLength:
            contract.type === 'HVAC' ? contract.contractLength : undefined,
          gasSavings:
            contract.type === 'HVAC' ? contract.gasSavings : undefined,
          maintenanceSavings:
            contract.type === 'HVAC' ? contract.maintenanceSavings : undefined,
          procurementSavings:
            contract.type === 'HVAC' ? contract.procurementSavings : undefined,
          // Lighting only fields.
          monthlyBlock:
            contract.type === 'LIGHTING' ? contract.monthlyBlock : undefined,
          // Lighting or HVAC fields.
          energyCommitment:
            ['LIGHTING', 'HVAC'].includes(contract.type) ?
              contract.energyCommitment
            : undefined,
          energyRate:
            ['LIGHTING', 'HVAC'].includes(contract.type) ?
              contract.energyRate
            : undefined,
          utilityRate:
            ['LIGHTING', 'HVAC'].includes(contract.type) ?
              contract.utilityRate
            : undefined,
        })
      }
    })
    const fullBodyFiltered = { ...fullBody, contracts: contractsFiltered }

    if (site && !isEqual(site, {})) {
      if (resourceProvider) {
        updateSiteExternalResource({
          siteId: site?.id,
          body: {
            externalId,
            resourceProvider,
          },
        })
      }
      updateSite(site.id, fullBodyFiltered)
    } else {
      addSite(fullBodyFiltered.customerId, fullBodyFiltered)
    }
  }

  renderBreadcrumbs() {
    const {
      match: {
        params: { customerId, siteId },
      },
      site,
      customer,
    } = this.props
    let items = [
      {
        href: '/account-management',
        text: 'Accounts',
      },
    ]

    if (customer && siteId && site) {
      items = [
        ...items,
        {
          href: '/account-management/customers',
          text: 'Customers',
        },
        {
          href: `/account-management/customers/${customerId}`,
          text: customer.name,
        },
        {
          href: `/account-management/customers/${customerId}/sites`,
          text: 'Sites',
        },
        {
          href: `/account-management/customers/${customerId}/sites/${siteId}`,
          text: site.validName,
        },
      ]
    } else if (customer && !siteId) {
      items = [
        ...items,
        {
          href: '/account-management/customers',
          text: 'Customers',
        },
        {
          href: `/account-management/customers/${customerId}`,
          text: customer.name,
        },
        {
          href: `/account-management/customers/${customerId}/sites`,
          text: 'Sites',
        },
      ]
    } else if (!customerId && siteId && site) {
      items = [
        ...items,
        {
          href: '/account-management/sites',
          text: 'Sites',
        },
        {
          href: `/account-management/sites/${siteId}`,
          text: site.validName,
        },
      ]
    } else {
      items = [
        ...items,
        {
          href: '/account-management/sites',
          text: 'Sites',
        },
      ]
    }

    return <Breadcrumbs items={items} />
  }

  render() {
    const {
      location: { pathname },
      loading,
      breadcrumbsLoading,
      site,
      siteExternalResources,
      siteId,
      customers,
      customer,
      customerId,
      error,
      actions,
      newOpportunity,
      isSearchingOpportunity,
    } = this.props
    let contractUpdated = false
    let contractFetchError = newOpportunity?.message || ''
    let updatedContracts = []
    let newOpportunityIndex = 0

    if (newOpportunity && site && site.contracts) {
      contractUpdated = true
      contractFetchError = newOpportunity.message
      updatedContracts = [...site.contracts, newOpportunity]
      newOpportunityIndex = newOpportunity.newOpportunityIndex
      // delete newOpportunity.newOpportunityIndex
    } else if (site && site.contracts) {
      updatedContracts = site.contracts
    }

    if (defaultSiteIds.includes(siteId)) {
      return <Redirect to='/' />
    }

    const { initializing } = this.state
    const formLoading = initializing || loading
    const isEdit = pathname.endsWith('/edit')
    const title = isEdit ? 'Edit Site' : 'Add Site'
    let formProps = {
      site: {
        ...site,
        contractUpdated,
        contractFetchError,
        contracts: updatedContracts,
        newOpportunityIndex,
        siteExternalResources,
      },
      isSearchingOpportunity,
      siteId,
      submitAction: this.handleSubmit,
      customers,
      error,
      goBack: this.goBack,
      isEdit,
      actions,
      newOpportunity,
    }

    if (customerId) {
      formProps = { ...formProps, customer }
    }

    return (
      <Styles>
        {!breadcrumbsLoading && this.renderBreadcrumbs()}
        <FormWidth>
          <Title>{title}</Title>
          {!isEdit && (
            <Description>
              <b>
                This form is for adding new sites metered with Redaptive or
                Leviton meters only.
              </b>{' '}
              Sites metered with Enertiv meters are created automatically.
            </Description>
          )}
          {!formLoading && <SiteForm {...formProps} />}
        </FormWidth>
      </Styles>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(messageActions, dispatch),
  },
})

const mapStateToProps = (state, props) => {
  const {
    match: { params },
  } = props
  const { requestStatus } = state
  const siteExternalResources = selectSiteExternalResources(state)
  const { status: siteStatus } = requestStatus.sites
  const { status: customerStatus } = requestStatus.customers
  const {
    status: formStatus,
    createdSiteId,
    editedSiteId,
  } = requestStatus.siteForm
  const { siteId, customerId } = params
  const { formError: error } = state.entities.sites.meta
  const { newOpportunity, isSearchingOpportunity } = state.entities.sites.meta
  let site = {}
  let customer
  const breadcrumbsLoading = [siteStatus, customerStatus].every(
    (s) => s === status.LOADING,
  )
  const loading = [siteStatus, customerStatus].some((s) => s === status.LOADING)

  if (newOpportunity || (siteId && siteStatus !== status.LOADING)) {
    site = state.entities.sites.byId[siteId] || {}
  }

  if (customerId) {
    customer = state.entities.customers.byId[customerId] || {}
  }

  const { items: customers } = selectCustomerListEntity(state)
  customers.sort(naturallySortCustomers)
  return {
    error,
    siteId,
    site,
    siteExternalResources,
    customerId,
    customer,
    customers,
    loading,
    breadcrumbsLoading,
    createdSiteId,
    editedSiteId,
    newOpportunity,
    isSearchingOpportunity,
    success: formStatus === status.LOADED,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteFormPage)
