import { queryStringify, handleAxiosError } from '../api/utils'
import moment, { tz } from 'moment'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { consoleBaseUrl, defaultHeaders } from '../api'
import { renderTimestamp } from '../ducks/utils'

const formatDate = (date, tz) => {
  console.log(date)
  if (date) {
    const formattedDate = renderTimestamp(date, tz, 'MM/DD/YYYY, hh:mm:ss A z')
    return formattedDate
  }
  return null
}

// API Calls
const updatedData = (data) =>
  data?.map((item) => {
    const dateFields = [
      'externalCreatedDate',
      'externalLastUsageDate',
      'externalEarliestCalibrationDate',
      'lastDataIngestionDate',
      'externalLastCommunicationDate',
      'externalLastCalibrationDate',
    ]
    const updatedItem = { ...item }
    dateFields.forEach((field) => {
      updatedItem[field] =
        formatDate(item[field], item?.siteTimezone || 'UTC') || null
    })
    return updatedItem || []
  })

const fetchSiteReadiness = async () => {
  const query = queryStringify({
    pageSize: 1000,
  })
  const url = `${consoleBaseUrl()}/api/water-meter-tool/sites/external-site-readiness/list?${query}`
  return axios
    .get(url, {
      headers: defaultHeaders(),
    })
    .then(({ data }) => updatedData(data?.results))
    .catch(handleAxiosError)
}

const fetchExternalMeterDetails = async ({ id, flow }) => {
  let body = {
    value: id,
    source: 'HYDROPOINT',
    key: 'REDAPTIVE_SITE_ID',
  }
  if (flow === 'organization') {
    body = {
      ...body,
      key: 'EXTERNAL_SITE_ID',
    }
  }
  if (flow === 'device') {
    body = {
      ...body,
      key: 'EXTERNAL_METER_ID',
    }
  }
  const url = `${consoleBaseUrl()}/api/water-meter-tool/external-meter-details`
  return axios
    .post(url, body, {
      headers: defaultHeaders(),
    })
    .then(({ data }) => updatedData(data))
    .catch(handleAxiosError)
}

// Queries
export const useSiteReadiness = () =>
  useQuery({
    queryKey: ['site-readiness'],
    queryFn: () => fetchSiteReadiness(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

export const useExternalMeterDetails = () => {
  return useMutation({
    mutationFn: (obj: { flow: string; id: string }) =>
      fetchExternalMeterDetails(obj),
    onSuccess: (data) => data,
  })
}
