import CustomerAndSiteIdSelector from '../../../../components/CustomerSiteSelector/CustomerSiteSelector'
import { Label, MeterWrapper, RadioGroup } from '../styles'
import Table from '../components/Table'
import LabelInput from '../components/Input'

export default function MeterDetail({
  selectedView,
  setSelectedView,
  id,
  setId,
}) {
  const onRadioChange = (event) => {
    setSelectedView(event.target.name)
  }
  return (
    <>
      <MeterWrapper>
        <p>
          Use this tool to query for the latest water meter status. This will
          help you quickly see if any meters are uncalibrated or are missing key
          dates. Note that fully calibrated meters appearing here may take a day
          to become available in site readiness tab above.
        </p>
        <RadioGroup>
          <input
            type='radio'
            checked={selectedView === 'customerAndSite'}
            name='customerAndSite'
            onChange={onRadioChange}
            disabled={!!id}
            id='customerAndSite'
          />
          <Label htmlFor='customerAndSite'>By Customer + Site</Label>
          <input
            type='radio'
            checked={selectedView === 'organization'}
            name='organization'
            onChange={onRadioChange}
            disabled={!!id}
            id='organization'
          />
          <Label htmlFor='organization'>Site/Organization ID</Label>
          <input
            type='radio'
            checked={selectedView === 'device'}
            name='device'
            onChange={onRadioChange}
            disabled={!!id}
            id='device'
          />
          <Label htmlFor='device'>By Meter/Device ID</Label>
        </RadioGroup>
        <p>
          Returns all HydroPoint meters currently assigned to the given customer
          and site.
        </p>
        {selectedView === 'customerAndSite' && (
          <CustomerAndSiteIdSelector
            getSiteId={setId}
            onReset={() => {
              setId('')
            }}
            disabled={!!id}
            id={id}
          />
        )}
        {selectedView === 'organization' && (
          <LabelInput
            disabled={!!id}
            label='Site/Organization ID'
            getId={setId}
            placeholder='Enter Site/Organization ID'
            id={id}
          />
        )}
        {selectedView === 'device' && (
          <LabelInput
            disabled={!!id}
            label='Meter/Device ID'
            getId={setId}
            placeholder='Enter Meter/Device ID'
            id={id}
          />
        )}
      </MeterWrapper>
      <Table id={id} flow={selectedView} />
    </>
  )
}
