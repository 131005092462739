import FormField from '../FormField'
import FormSection from '../FormSection'
import Input from '../Input'
import ListSelector from '../ListSelector'
import { Link, BlackText } from './styles'

const EXTERNAL_RESOURCES = [
  { id: 'WATTS_WATER', name: 'Watts Water' },
  { id: 'HYDROPOINT', name: 'HydroPoint Water' },
]

export default function ExternalIntegrationsSection({
  setFieldValue,
  values,
  initialValues,
  errors,
}) {
  const { externalId, resourceProvider } = values
  const {
    externalId: initialExternalId,
    resourceProvider: initialResourceProvider,
  } = initialValues
  const isHydropoint = resourceProvider === 'HYDROPOINT'
  const isWattsWater = resourceProvider === 'WATTS_WATER'

  const renderTitle = () => {
    if (isHydropoint) {
      return 'Hydropoint Site ID'
    } else if (isWattsWater) {
      return 'Watts Nexa Organization ID'
    }
    return 'External Resource ID'
  }
  return (
    <FormSection
      title='External Integrations'
      description='The following fields are optional, however they must be completed in order to display meter data in the Energy Dashboard.'
    >
      <FormField
        title='Manufacturer'
        name='resourceProvider'
        renderField={() => (
          <div>
            <ListSelector
              editDotLeft='-20px'
              items={EXTERNAL_RESOURCES}
              selectedItem={EXTERNAL_RESOURCES.find(
                ({ id }) => id === resourceProvider,
              )}
              unsettable={false}
              updateValue={({ value }) => {
                if (setFieldValue) {
                  setFieldValue('resourceProvider', value)
                }
              }}
              notSetLabelText='Select External System'
              edited={initialResourceProvider !== resourceProvider}
            />
          </div>
        )}
      />
      <FormField
        name='externalId'
        title={renderTitle()}
        requiredText={errors.externalId}
        showRequired={errors.externalId}
        renderField={() => (
          <Input
            alwaysDraw
            editDotLeft='-12px'
            type='text'
            hideEditDotOnHover={false}
            key='externalId'
            name='externalId'
            edited={initialExternalId !== externalId}
            value={externalId}
            placeholder='--'
            disabled={!!!resourceProvider}
            onChange={(e) => {
              if (e.target && setFieldValue) {
                setFieldValue('externalId', e.target.value || '')
              }
            }}
          />
        )}
      />
      {isWattsWater && (
        <span>
          <BlackText>Go to: </BlackText>
          <Link href='https://app.nexaplatform.com' target='_blank'>
            https://app.nexaplatform.com
          </Link>
        </span>
      )}
    </FormSection>
  )
}
