/* eslint-disable func-names */
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import moment from 'moment'
import * as Yup from 'yup'
// See https://github.com/jquense/yup/issues/345#issuecomment-634718990
export const uniquePropertyTest = function (value, propertyName, message) {
  if (
    this.parent
      .filter((v) => v !== value)
      .some((v) => get(v, propertyName) === get(value, propertyName))
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    })
  }

  return true
}
// See https://github.com/jquense/yup/issues/345#issuecomment-634718990
Yup.addMethod(Yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    return uniquePropertyTest.call(this, value, propertyName, message)
  })
})
// See https://github.com/jquense/yup/issues/345#issuecomment-634718990
Yup.addMethod(Yup.object, 'uniqueProperties', function (propertyNames) {
  return this.test('unique', '', function (value) {
    const errors = propertyNames
      .map(([propertyName, message]) => {
        try {
          return uniquePropertyTest.call(this, value, propertyName, message)
        } catch (error) {
          return error
        }
      })
      .filter((error) => error instanceof Yup.ValidationError)

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors)
    }

    return true
  })
})

export default Yup.object().shape({
  address1: Yup.string().required(),
  contracts: Yup.array().of(
    Yup.object()
      .shape({
        contractLength: Yup.lazy((value) => {
          if (!value && value !== 0) {
            return Yup.mixed().nullable()
          }

          return Yup.number().label('Contract Length').min(0)
        }),
        energyRate: Yup.lazy((value) => {
          if (!value && value !== 0) {
            return Yup.mixed().nullable()
          }

          return (
            Yup.number()
              .label('Energy Rate')
              .min(0) // The server rounds anything above 0.99995 to 1 and throws an
              // exception.
              .lessThan(0.99995)
          )
        }),
        gasSavings: Yup.lazy((value) => {
          if (!value && value !== 0) {
            return Yup.mixed().nullable()
          }

          return Yup.number().label('Gas Savings Contract Value').min(0)
        }),
        maintenanceSavings: Yup.lazy((value) => {
          if (!value && value !== 0) {
            return Yup.mixed().nullable()
          }

          return Yup.number().label('Maintenance Savings Contract Value').min(0)
        }),

        procurementSavings: Yup.lazy((value) => {
          if (!value && value !== 0) {
            return Yup.mixed().nullable()
          }

          return Yup.number().label('Procurement Savings Contract Value').min(0)
        }),
        utilityRate: Yup.lazy((value) => {
          if (!value && value !== 0) {
            return Yup.mixed().nullable()
          }

          return (
            Yup.number()
              .label('Utility Rate')
              .min(0) // The server rounds anything above 0.99995 to 1 and throws an
              // exception.
              .lessThan(0.99995)
          )
        }),
      })
      .uniqueProperties([['opportunityId', 'Opportunity Id must be unique']]),
  ),
  country: Yup.string().required(),
  customerId: Yup.string().required(),
  display: Yup.string().required(),
  ingestionDataStartElectricity: Yup.string().test(
    'ingestionDataStartElectricity',
    'Must be a valid date',
    function test() {
      const { ingestionDataStartElectricity } = this.parent
      if (!ingestionDataStartElectricity) return true
      return moment(
        ingestionDataStartElectricity,
        moment.ISO_8601,
        true,
      ).isValid()
    },
  ),
  ingestionDataStartNaturalGas: Yup.string().test(
    'ingestionDataStartNaturalGas',
    'Must be a valid date',
    function test() {
      const { ingestionDataStartNaturalGas } = this.parent
      if (!ingestionDataStartNaturalGas) return true
      return moment(
        ingestionDataStartNaturalGas,
        moment.ISO_8601,
        true,
      ).isValid()
    },
  ),
  ingestionDataStartWater: Yup.string().test(
    'ingestionDataStartWater',
    'Must be a valid date',
    function test() {
      const { ingestionDataStartWater } = this.parent
      if (!ingestionDataStartWater) return true
      return moment(ingestionDataStartWater, moment.ISO_8601, true).isValid()
    },
  ),
  theme: Yup.string().required(),
  timezone: Yup.string().required(),
  externalId: Yup.string().when('resourceProvider', {
    is: (resourceProvider) => !!resourceProvider,
    then: Yup.string().required('*Required'),
    otherwise: Yup.string(),
  }),
  electricUtilityRate: Yup.number()
    .required('*Required')
    .test('electricUtilityRate', 'Must be between 0 and 1', function test() {
      const { electricUtilityRate } = this.parent
      return !(electricUtilityRate > 1 || electricUtilityRate < 0)
    }),
})
