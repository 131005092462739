import { Provider, ProviderProps } from '@rollbar/react'
import { ReactNode } from 'react'
import Rollbar from 'rollbar'

const rollbarConfig: ProviderProps['config'] = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: import.meta.env.MODE !== 'development',
  payload: {
    client: {
      javascript: {
        code_version: import.meta.env.VITE_SHA1,
        source_map_enabled: true,
      },
    },
    environment: import.meta.env.VITE_DEPLOY_ENV,
    source_map_enabled: true,
    server: {
      root: '../../',
      branch: import.meta.env.VITE_GIT_BRANCH,
    },
    code_version: import.meta.env.VITE_SHA1,
    guess_uncaught_frames: true,
    person: {
      id: '',
      email: '',
    },
  },
}

export const rollbar = new Rollbar(rollbarConfig)

const RollbarProvider = ({ children }: { children: ReactNode }) => (
  <Provider instance={rollbar}>{children}</Provider>
)

export default RollbarProvider
