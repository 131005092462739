import styled from 'styled-components'
import ListSelector from '../ListSelector'
import { colors } from '../../utils/themes'

export const StyledLabel = styled.p`
  color: ${colors.gray2};
  font-weight: 600;
`

export const SelectContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  ${StyledLabel} {
    margin: 0 0 8px;
  }
`
export const StyledSelect = styled(ListSelector)`
  width: 300px;
  height: 36px;
`

export const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  button {
    &:first-child {
      margin-left: 0;
    }
  }
`
