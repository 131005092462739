import axios from 'axios'
import FileSaver from 'file-saver'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { Col1Styles } from './LightingAsBuiltsManagerPage'
import AuditVendorValidationComponent from './UtilityData/AuditVendorValidationComponent'
import { CloseDivStyled, ModalInnerStyled } from './UtilityData/UploadModal'
import { consoleApiUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import FeatureValidator from '../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../authorization/features'
import ActionCard from '../../components/ActionCard'
import ActionCardsPage from '../../components/ActionCardsPage'
import CloseOutline from '../../components/Icons/svg/CloseOutline'
import { actions } from '../../ducks/meterStatus'
import '../../types'
import type { FTHistory } from '../../types'
import { isVariantActive, zIndices } from '../../utils'
import { GROUPS } from '../../utils/constants'
import { colors } from '../../utils/themes'
import { TabUrlEnum } from '../IntegrationSubmissions/helpers/utils'
import * as status from '../../constants/status'

type FTProps = {
  actions: {
    getInstalledMeters: (...args: Array<any>) => any
    getMeterHistory: (...args: Array<any>) => any
  }
  history: FTHistory
  historyError: string | null | undefined
  historyLoading: boolean
  inManufacturingGroup: boolean
  isEnergyStarAdmin: boolean
  hasMeterInstallAdminAccess: boolean
  hasGroupedSubmissionsAccess: boolean
  hasErrorCodePermissions: boolean
  hasFullEVPermissions: boolean
  installedLoading: boolean
  statusError: string | null | undefined
  statusLoading: boolean
  hasHydropointToolsPermission: boolean
}
const ModalOuterStyled = styled.div`
  background-color: rgba(74, 74, 74, 0.4);
  color: ${colors.blue3};
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: ${zIndices.ModalScenario};
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }

  ${Col1Styles} {
    margin-right: 0px;
  }
`
class ReportsPage extends React.PureComponent<
  FTProps,
  { showPopup: boolean; showClosePopup: boolean; errorCodeLoading: boolean },
  onClose
> {
  navigateHvacAssetManager = () =>
    this.props.history.push('/tools/hvac-asset-manager')

  navigateLightingAsBuiltsManager = () =>
    this.props.history.push('/tools/lighting-as-builts-manager')

  navigateMeterExport = () => this.props.history.push('/reports/meter-export')

  navigateMeterInstall = () => this.props.history.push('/reports/meter-install')

  navigateMeterInstallSubmissions = () =>
    this.props.history.push('/reports/meter-install-submissions')

  navigateMeterInstallSubmissionsV0 = () =>
    this.props.history.push('/reports/meter-install-submissions-v0')

  navigateManageUtilityData = () =>
    this.props.history.push('/reports/manage-utility-data')

  navigateRealTimeMeterStatus = () =>
    this.props.history.push('/reports/meter-status')

  navigateEnergyStarIntegration = () =>
    this.props.history.push(
      `/integration/energy-star/submissions/${TabUrlEnum.UNDER_MAINTENANCE}`,
    )

  navigateFullEV = () =>
    this.props.history.push('/reports/electron-verify-dashboard')

  navigateFullEVV1 = () =>
    this.props.history.push('/reports/electron-verify-dashboard-v1')

  navigateHydropointMeterTools = () =>
    this.props.history.push('/reports/hydroint-meter-tools')

  navigateToSitePrioritization = () => this.props.history.push('/ernesto')

  constructor(props: any) {
    super(props)
    this.state = {
      showPopup: false,
      showClosePopup: true,
      errorCodeLoading: false,
    }
  }

  handleClickUploadVendorAuditFile = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }))
  }

  handleCloseVendorAuditFilePopup = () => {
    this.setState({ showPopup: false })
  }

  updateClosePopupVisibility = (shouldShow: true) => {
    this.setState({ showClosePopup: shouldShow })
  }

  handleErrorCodeReport = () => {
    this.setState({ errorCodeLoading: true })
    axios
      .get(`${consoleApiUrl()}/meter/status/gas-status-codes?timePeriod=60`, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => {
        this.setState({ errorCodeLoading: false })
        FileSaver.saveAs(response.data, `Error_code_status.csv`)
      })
      .catch((e) => {
        this.setState({ errorCodeLoading: false })
        handleAxiosError(e)
      })
  }

  renderVendorAuditFilePopup = () => {
    const { showPopup, showClosePopup } = this.state
    if (showPopup) {
      return (
        <ModalOuterStyled>
          <ModalInnerStyled>
            <AuditVendorValidationComponent
              auditFileUploadResponse=''
              closePopup={this.handleCloseVendorAuditFilePopup}
              updateClosePopupVisibility={this.updateClosePopupVisibility}
            />
            {showClosePopup && (
              <CloseDivStyled onClick={this.handleCloseVendorAuditFilePopup}>
                <CloseOutline />
              </CloseDivStyled>
            )}
          </ModalInnerStyled>
        </ModalOuterStyled>
      )
    }
    return null
  }

  render() {
    const {
      historyLoading,
      installedLoading,
      historyError,
      statusLoading,
      statusError,
      inManufacturingGroup,
      isEnergyStarAdmin,
      hasMeterInstallAdminAccess,
      hasErrorCodePermissions,
      hasFullEVPermissions,
      hasHydropointToolsPermission,
    } = this.props
    const { errorCodeLoading } = this.state
    return (
      <>
        <ActionCardsPage>
          <FeatureValidator feature={AuthorizedFeatures.rtMeterStatus}>
            <ActionCard
              buttonText='MAC Address Lookup'
              description={
                <>
                  Get up-to-the-minute meter status,{' '}
                  <b>for Redaptive meters only.</b>
                </>
              }
              handleClick={this.navigateRealTimeMeterStatus}
              title='Real-Time Meter Status Tool'
            />
          </FeatureValidator>
          {/*
          TODO: Technical Debt: Remove manual group check once we resolve
          the backend permissions issue with 'meter/status'.
          Be sure to remove it from:
            - The Props type definition at the top of the file
            - the component's props
            - mapStateToProps
          */}
          {!inManufacturingGroup && (
            <FeatureValidator feature={AuthorizedFeatures.offlineMeters}>
              <ActionCard
                buttonText='Download CSV'
                buttonDisabled={installedLoading}
                buttonLoading={installedLoading}
                description={
                  'Find out which meters are offline, updated nightly. Redaptive meters are' +
                  ' considered to be offline if they haven’t reported in the last 2 hours. Enertiv' +
                  ' and Leviton meters are considered offline if they haven’t reported in 48 hours.'
                }
                error={historyError}
                handleClick={() => {
                  if (!statusLoading) {
                    this.props.actions.getInstalledMeters()
                  }
                }}
                title='Offline Meter Report'
              />
            </FeatureValidator>
          )}
          <FeatureValidator feature={AuthorizedFeatures.installedMeters}>
            <ActionCard
              buttonDisabled={historyLoading}
              buttonLoading={historyLoading}
              buttonText='Download CSV'
              description='Get a list of every meter that has ever been installed.'
              error={statusError}
              handleClick={() => {
                if (!historyLoading) {
                  this.props.actions.getMeterHistory()
                }
              }}
              title='Installed Meters List'
            />
          </FeatureValidator>
          {!inManufacturingGroup && (
            <FeatureValidator feature={AuthorizedFeatures.meterExport}>
              <ActionCard
                buttonText='Export Meter Data'
                description='Export meter data for selected sites, or all sites.'
                handleClick={this.navigateMeterExport}
                title='Meter Data Export'
              />
            </FeatureValidator>
          )}
          <FeatureValidator feature={AuthorizedFeatures.uploadHvac}>
            <ActionCard
              buttonText='MANAGE ASSETS'
              description='Add or update HVAC asset records.'
              handleClick={this.navigateHvacAssetManager}
              title='HVAC Asset Manager'
            />
          </FeatureValidator>
          <FeatureValidator feature={AuthorizedFeatures.meterInstallReport}>
            <ActionCard
              buttonText='Get Installation Report'
              description='Find all Redaptive meters installed within a given date range. Returns install date and CT count.'
              handleClick={this.navigateMeterInstall}
              title='Meter Installation Report'
            />
          </FeatureValidator>
          <FeatureValidator
            feature={AuthorizedFeatures.lightingAsBuiltsManager}
          >
            <ActionCard
              buttonText='MANAGE Lighting AS-Builts'
              description='Add or update Lighting As-Built records, Lighting Measure Code table, or Lighting Controls table.'
              handleClick={this.navigateLightingAsBuiltsManager}
              title='Lighting As-Builts Manager'
            />
          </FeatureValidator>
          {hasMeterInstallAdminAccess && (
            <ActionCard
              buttonText='View Records'
              description='Meter installation and configuration submissions coming from the Redaptive Meter Install mobile app'
              handleClick={this.navigateMeterInstallSubmissions}
              title='Meter Install Submissions'
            />
          )}
          {isEnergyStarAdmin && (
            <FeatureValidator
              feature={AuthorizedFeatures.energyStarIntegration}
            >
              <ActionCard
                buttonText='VIEW ENERGY STAR'
                description='Energy Star Submissions'
                handleClick={this.navigateEnergyStarIntegration}
                title='Energy Star'
              />
            </FeatureValidator>
          )}
          <FeatureValidator feature={AuthorizedFeatures.manageUtilityData}>
            <ActionCard
              buttonText='Upload Utility Data'
              description='Import data for Utility profiles, Meters, Invoices, Service Address for various sites'
              handleClick={this.navigateManageUtilityData}
              title='Manage Utility Data'
            />
          </FeatureValidator>
          <FeatureValidator feature={AuthorizedFeatures.proposalsEngine}>
            <ActionCard
              buttonText='Validate Audit Files'
              description='Please upload the vendor audit files to validate'
              handleClick={this.handleClickUploadVendorAuditFile}
              title='Audit Vendor Files'
            />
          </FeatureValidator>
          {isVariantActive('TEAL_1365') && (
            <ActionCard
              buttonText='View'
              description='Run Sites through Smart Projects models, and get back information to help prioritize further information gathering, analysis, and audits.'
              handleClick={this.navigateToSitePrioritization}
              title='Ernesto'
            />
          )}
          {hasErrorCodePermissions && (
            <ActionCard
              buttonText='Download Report'
              description='Find all the Gemini Gas meters which are reporting error codes. The error codes will be updated hourly in the databse'
              handleClick={this.handleErrorCodeReport}
              title='Error Code Report'
              buttonDisabled={errorCodeLoading}
              buttonLoading={errorCodeLoading}
            />
          )}
          {hasFullEVPermissions && (
            <ActionCard
              buttonText='View Electron Verify'
              description='List of all meters for which the full EV process have been executed. Ability to start full EV check on installed meters'
              handleClick={this.navigateFullEV}
              title='Electron Verify'
            />
          )}
          {hasFullEVPermissions && isVariantActive('DC-203') && (
            <ActionCard
              buttonText='View Electron Verify V1'
              description='List of all meters for which the full EV process have been executed. Ability to start full EV check on installed meters'
              handleClick={this.navigateFullEVV1}
              title='Electron Verify V1'
            />
          )}
          {hasHydropointToolsPermission && (
            <ActionCard
              buttonText='View hydropoint meter tools'
              description='Find all the calibrated HydroPoint meters. Find the meter status of any HydroPoint meter available in timestream.'
              handleClick={this.navigateHydropointMeterTools}
              title='HydroPoint Water Meter Tools'
            />
          )}
          {hasMeterInstallAdminAccess && (
            <ActionCard
              buttonText='View Records'
              description='Meter installation and configuration submissions received from the Redaptive Meter Install mobile app, ordered by Meter MACs.'
              handleClick={this.navigateMeterInstallSubmissionsV0}
              title='Meter Install Submissions - Meter View'
            />
          )}
        </ActionCardsPage>

        {this.renderVendorAuditFilePopup()}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})

const mapStateToProps = (state) => {
  const {
    requestStatus,
    auth: { groups },
  } = state
  const { error: statusError, status: installedStatus } =
    requestStatus.installedStatus
  const { error: historyError, status: historyStatus } =
    requestStatus.historyStatus
  const inManufacturingGroup = groups ? groups.includes('manufacturing') : false
  const isEnergyStarAdmin =
    groups ? groups.includes(GROUPS.ENERGY_STAR_ADMIN) : false
  const hasMeterInstallAdminAccess =
    groups ?
      groups.includes('meter-install-app-admin-access') ||
      groups.includes('admin')
    : false

  const hasErrorCodePermissions =
    groups ?
      groups.includes('admin') ||
      groups.includes('operations-support') ||
      groups.includes('account-managers')
    : false
  const hasHydropointToolsPermission =
    groups ?
      groups.includes(GROUPS.ACCOUNT_MANAGER) ||
      groups.includes(GROUPS.OPERATIONS_SUPPORT) ||
      groups.includes(GROUPS.ADMIN)
    : false

  const hasFullEVPermissions = hasErrorCodePermissions
  return {
    inManufacturingGroup,
    isEnergyStarAdmin,
    statusError,
    historyError,
    installedLoading: installedStatus === status.LOADING,
    historyLoading: historyStatus === status.LOADING,
    hasMeterInstallAdminAccess,
    hasErrorCodePermissions,
    hasFullEVPermissions,
    hasHydropointToolsPermission,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage)
