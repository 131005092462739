import Tippy from '@tippyjs/react'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
  CircuitSectionStyled,
  EditActionStyled,
  HeaderStyled,
  MeterIconWrapperStyled,
  PanelDropdownContainer,
  PanelHeading,
  SectionTitleStyled,
  StyledPanelSelector,
  TextCellStyled,
  ViewPhotosButton,
  Wrapper,
} from './style'
import ActionPaneView from '../../components/ActionPaneView'
import Breadcrumbs from '../../components/Breadcrumbs'
import ErrorMessage from '../../components/ErrorMessage'
import MeteredCircuit from '../../components/Icons/svg/MeteredCircuit'
import UnMeteredCircuit from '../../components/Icons/svg/UnMeteredCircuit'
import {
  findArrayType,
  getNumericPart,
} from '../../components/ModalPanelForm3/utils'
import FormikForm from '../../components/PanelConfiguration/FormikForm'
import {
  renderCreatePanelConfirm,
  renderCreateSwitchBoardConfirm,
  renderDeletePanelConfirm,
  renderUpdateCircuitsConfirm,
  renderUpdateSwitchesConfirm,
} from '../../components/PanelConfiguration/Modals'
import PanelCircuitsTable from '../../components/PanelConfiguration/PanelCircuitsTable'
import PanelSelectorItem from '../../components/PanelSelectorItem'
import EditNoteIconStyled from '../../components/ProposalsEngine/EditNoteIconStyled'
import Spinner from '../../components/Spinner'
import Title from '../../components/Title'
import VerticalTable from '../../components/VerticalTable'
import {
  actions as buildingSystemsActions,
  FTBuildingSystemSummary,
  selectors,
} from '../../ducks/buildingSystems'
import type { FTCustomer, FTFetchCustomerAction } from '../../ducks/customers'
import {
  actions as customerActions,
  selectCustomersById,
} from '../../ducks/customers'
import {
  actions as messagesActions,
  FTMessageInput,
} from '../../ducks/messages'
import { utils } from '../../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'
import { fieldNameMap } from '../../ducks/meters/generation'
import { actions as modalActions, FTConfirmModal } from '../../ducks/modal'
import type { FTModalPanelForm2, FTModalPanelForm3 } from '../../ducks/modal'
import type {
  FTDeletePanelCircuitsAction,
  FTFetchPanelCircuitsAction,
  FTPanelCircuit,
} from '../../ducks/panelCircuits'
import {
  actions as panelCircuitActions,
  selectPanelCircuitsEntity,
} from '../../ducks/panelCircuits'
import {
  actions as panelMetaDataActions,
  selectPanelMetaData,
} from '../../ducks/panelPhotos'
import {
  actions as panelActions,
  FTPanelSummary,
  selectPanelListEntity,
  selectTempMeta,
} from '../../ducks/panels'
import type {
  FTAddPanelAction,
  FTDeletePanelAction,
  FTPanel,
  FTPanelFeed,
} from '../../ducks/panels'
import {
  actions as siteActions,
  FTFetchSiteAction,
  selectSiteEntity,
} from '../../ducks/sites'
import type { FTEntity } from '../../ducks/types'
import type { FTFormFieldEvent, FTRouterMatch, FTWithRouter } from '../../types'
import { PanelMetaData } from '../../types/panelConfiguration/PanelConfigurationTypes'
import { SiteById } from '../../types/SiteTypes'
import { getValueFromEvent } from '../../utils'
import ctSizeMapping from '../../utils/panel'
import {
  columnWidths,
  getCreatedCircuits,
  getSortedCircuits,
} from '../../utils/panelConfiguration'

type PanelDetailsPageProps = {
  actions: {
    addPanel: (params: FTAddPanelAction) => null
    resetPanel: () => null
    fetchPanelList: (params: { siteId: string }) => null
    fetchAllPanels: (params: { siteId: string }) => null
    fetchSite: (params: FTFetchSiteAction) => null
    fetchCustomer: (params: FTFetchCustomerAction) => null
    fetchPanelCircuits: (params: FTFetchPanelCircuitsAction) => null
    fetchBuildingSystems: () => null
    updatePanelCircuits: (params: Array<FTPanelCircuit>) => null
    showConfirmModal: (arg0: FTConfirmModal) => null
    showModalPanelForm2: (arg0: FTModalPanelForm2) => null
    showModalPanelForm3: (arg0: FTModalPanelForm3) => null
    hideModal: () => null
    showModalPanelPhotosView: () => null
    fetchPanelPhotos: (params: { objectId: string }) => null
    fetchPhotosDownloadUrl: (params: { fileIds: Array<string> }) => null
    deletePanel: (arg0: FTDeletePanelAction) => null
    deletePanelCircuits: (arg0: FTDeletePanelCircuitsAction) => null
    showMessage: (arg0: FTMessageInput) => null
    hideMessage: (value: string) => null
    resetPanelCircuits: () => null
  }
  match: FTRouterMatch
  addedPanelId: string
  addLoaded: string
  panels: Array<FTPanelSummary>
  panelsLoading: boolean
  panelsById: PanelsById
  panelEntity: FTEntity
  panelDeleted: boolean
  panelDeleting: boolean
  circuits: Array<FTPanelCircuit>
  circuitsById: CircuitsById
  circuitsLoading: boolean
  circuitsUpdating: boolean
  circuitsUpdateError: string
  circuitsDeleteError: string
  circuitsDeleting: boolean
  buildingSystems: Array<FTBuildingSystemSummary>
  buildingSystemsById: Record<string, FTBuildingSystemSummary>
  customerEntity: Record<string, FTCustomer>
  siteById: SiteById
  tempPanelObject: FTPanelSummary
  panelMetaData: PanelMetaData
} & FTWithRouter

const PanelDetailsPage = (props: PanelDetailsPageProps) => {
  const {
    actions,
    addedPanelId,
    addLoaded,
    buildingSystems,
    buildingSystemsById,
    circuits,
    circuitsById,
    circuitsLoading,
    circuitsUpdating,
    circuitsUpdateError,
    circuitsDeleteError,
    circuitsDeleting,
    customerEntity,
    panels,
    panelsById,
    panelsLoading,
    panelEntity,
    panelDeleted,
    panelDeleting,
    siteById,
    tempPanelObject,
    match: {
      url,
      params: { siteId, panelId, customerId },
    },
    panelMetaData,
  } = props
  const { pathname } = useLocation()
  const history = useHistory()

  const inCreateMode =
    pathname.endsWith('/edit') && addedPanelId && addedPanelId.length > 0
  const inEditMode = pathname.endsWith('/edit')
  const { panelPhotos } = panelMetaData
  const [isPanelFlagUpdated, setIsPanelFlagUpdated] = React.useState(false)

  // error state
  const [error, setError] = React.useState('')

  const firstLoad = React.useRef(true)

  let panel = panelsById[panelId]
  const isNumbered =
    panel && panel.isNumbered !== undefined ? panel.isNumbered : undefined

  const dirtyCircuitsUpdated: boolean =
    !circuitsUpdating &&
    !circuitsUpdateError &&
    !circuitsDeleteError &&
    !circuitsDeleting
  const circuitsUpdatedSuccessfully: boolean =
    isPanelFlagUpdated && dirtyCircuitsUpdated
  const customerName =
    customerId &&
    (customerEntity[customerId]?.validName || customerEntity[customerId]?.name)
  const columnDefinitions = useMemo(
    () => [
      { accessor: 'isMetered', header: 'Metered' },
      {
        accessor: 'breakerNumber',
        header: isNumbered ? 'Breaker #' : 'Switch Name',
      },
      { accessor: 'phase', header: 'Phase' },
      { accessor: 'breakerType', header: 'Breaker Type' },
      { accessor: 'phaseGroupSummary', header: 'Phase Group' },
      { accessor: 'panelFeedName', header: 'Panel Feed To' },
      { accessor: 'description', header: 'Circuit Description' },
      { accessor: 'buildingSystemId', header: 'Building System' },
      { accessor: 'equipmentName', header: 'Equipment Name' },
      { accessor: 'buildingArea', header: 'Building Area' },
      { accessor: 'amperage', header: 'Amperage' },
      { accessor: 'CTSizeExpected', header: 'CT Size Expected' },
    ],
    [isNumbered],
  )
  const breadcrumbs = React.useMemo(
    () => [
      {
        href: '/account-management',
        text: 'Accounts',
      },
      ...(customerId ?
        [
          {
            href: '/account-management/customers',
            text: 'Customers',
          },
          {
            href: `/account-management/customers/${customerId}`,
            text: customerName || '--',
          },
          {
            href: `/account-management/customers/${customerId}/sites/${siteId}`,
            text:
              siteById && siteById[siteId] ? siteById[siteId].display : '--',
          },
        ]
      : [
          {
            href: '/account-management/sites',
            text: 'Sites',
          },
          {
            href: `/account-management/sites/${siteId}`,
            text:
              siteById && siteById[siteId] ? siteById[siteId].display : '--',
          },
        ]),
    ],
    [customerId, customerName, siteId, siteById],
  )

  const getColumnCell = useCallback(
    (cellProps) => {
      const {
        column: { id },
        original: { macAddress, meterId },
        value,
      } = cellProps
      let cell = null
      switch (id) {
        case 'isMetered':
          cell = (
            <MeterIconWrapperStyled>
              {value ?
                <Tippy content={macAddress}>
                  <Link to={`../.../../meters/${meterId}/configuration`}>
                    <MeteredCircuit />
                  </Link>
                </Tippy>
              : <UnMeteredCircuit />}
            </MeterIconWrapperStyled>
          )
          break
        case 'breakerType':
          cell =
            value ?
              <Tippy
                content={
                  utils.breakerTypes[value as keyof typeof utils.breakerTypes]
                }
                delay={500}
              >
                <TextCellStyled>
                  {utils.breakerTypes[value as keyof typeof utils.breakerTypes]}
                </TextCellStyled>
              </Tippy>
            : '--'
          break
        case 'buildingSystemId':
          cell =
            value ?
              <Tippy content={buildingSystemsById[value]?.name} delay={500}>
                <TextCellStyled>
                  {buildingSystemsById[value]?.name}
                </TextCellStyled>
              </Tippy>
            : '--'
          break
        case 'phaseGroupSummary':
          cell =
            value ?
              <Tippy content={value?.name} delay={500}>
                <TextCellStyled>{value?.name}</TextCellStyled>
              </Tippy>
            : '-'
          break
        default:
          cell =
            value ?
              <Tippy content={value} delay={500}>
                <TextCellStyled>{value}</TextCellStyled>
              </Tippy>
            : '-'
      }
      return cell
    },
    [buildingSystemsById],
  )

  const generateColumns = useCallback(() => {
    const getColWidthForBreakerNumber = () =>
      isNumbered ?
        { ...columnWidths.breakerNumber }
      : { ...columnWidths.switchName }
    return columnDefinitions.map(({ accessor, header }) => {
      switch (accessor) {
        case 'isMetered':
          return {
            accessor,
            Header: header,
            Cell: getColumnCell,
            ...columnWidths.isMetered,
          }
        case 'breakerNumber':
          return {
            accessor,
            Header: header,
            Cell: getColumnCell,
            ...getColWidthForBreakerNumber(),
          }
        case 'breakerType':
          return {
            accessor,
            Header: header,
            Cell: getColumnCell,
            ...columnWidths.breakerType,
          }
        case 'buildingSystemId':
          return {
            accessor,
            Header: header,
            Cell: getColumnCell,
            ...columnWidths.buildingSystemId,
          }
        default:
          return {
            accessor,
            Header: header,
            Cell: getColumnCell,
            ...columnWidths[accessor],
          }
      }
    })
  }, [columnDefinitions, getColumnCell, isNumbered])

  const readOnlyColumns = generateColumns()

  // Once the panel is created, Redirect to the panel Id and clear the temp state
  React.useEffect(() => {
    if (isPanelFlagUpdated && addedPanelId && addLoaded && history) {
      history.push(url.split('/').slice(0, -1).concat(addedPanelId).join('/'))
      actions.resetPanel()
    }
  }, [isPanelFlagUpdated, addedPanelId, addLoaded, history, actions, url])

  React.useEffect(() => {
    actions.fetchAllPanels({
      siteId,
    })
    actions.fetchSite({
      siteId,
    })
    actions.fetchBuildingSystems()
    actions.fetchCustomer({
      customerId,
    })
  }, [actions, customerId, siteId])
  // Get out of Edit mode once the panel circuits are updated successfully updated
  React.useEffect(() => {
    if (!inCreateMode && circuitsUpdatedSuccessfully && history) {
      history.goBack()
    }
  }, [circuitsUpdatedSuccessfully, history])
  // Go to the panels list once the panel is successfully deleted
  React.useEffect(() => {
    if (panelDeleted && !panelDeleting && history) {
      history.push(url.split('/').slice(0, -1).join('/'))
    }
  }, [history, panelDeleted, panelDeleting, url])

  // Fetch the panel circuits whenever the panelId changes or when the panel circuits are updated
  React.useEffect(() => {
    if (panelId) {
      if (firstLoad.current || circuitsUpdatedSuccessfully) {
        actions.fetchPanelCircuits({
          panelId,
        })
        if (panelId) {
          const objectId = `objectId=${panelId}/Location&objectId=${panelId}/Configuration&objectId=${panelId}/Box&objectId=${panelId}/Schedule&objectId=${panelId}/BreakerTypes
          `
          actions.fetchPanelPhotos({ objectId })
        }
      }
      if (firstLoad.current) {
        firstLoad.current = false
      }
    }
  }, [panelId, circuitsUpdatedSuccessfully, actions])

  React.useEffect(() => {
    if (inEditMode && panelsById[panelId]) {
      const panel = panelsById[panelId]
      if (tempPanelObject.fromPanelModal) {
        const photosUpdatedMessage =
          tempPanelObject.photosUpdated ?
            `${panel.name} panel is updated & photos are associated to the ${panel.name} panel. `
          : ''

        let updatedMessage = `${panel.name} panel is updated`
        if (inCreateMode) {
          updatedMessage = ''
        }
        const message = `${
          photosUpdatedMessage.length > 0 ?
            photosUpdatedMessage
          : updatedMessage
        }Add any ${
          isNumbered ? 'circuits' : 'switches'
        } to associate to the panel.`
        actions.showMessage({
          messageId: 'panel-photos-updated',
          title: message,
          type: 'success',
          position: 'fixed',
        })
      }
    } else {
      actions.hideMessage('panel-photos-updated')
    }
  }, [
    tempPanelObject,
    inEditMode,
    panelsById,
    panelId,
    inCreateMode,
    isNumbered,
    actions,
  ])
  React.useEffect(
    () => () => {
      actions.hideModal()
    },
    [actions],
  )

  const onShowPanelPhotosView = () => {
    if (panelPhotos.length > 0) {
      const fileIds = panelPhotos.map((item: { id: string }) => item.id)
      actions.fetchPhotosDownloadUrl({ fileIds })
    }
    actions.showModalPanelPhotosView()
  }
  const renderViewPhotos = () => (
    <ViewPhotosButton onClick={onShowPanelPhotosView}>
      View Photos
    </ViewPhotosButton>
  )

  const renderPanelFeed = (panelFeeds) => {
    const hardwareIds = [...new Set(panelFeeds.map((item) => item.hardwareId))]
    const ctSizes = [
      ...new Set(
        panelFeeds.map((item: FTPanelFeed) => {
          const ctObj = ctSizeMapping[item.ctSize]
          if (ctObj.text === '-') return ctObj.subText
          return ctObj.text
        }),
      ),
    ]
    const phaseA = [
      ...new Set(
        panelFeeds
          .filter((item: FTPanelFeed) => item.phase === 'Phase 1')
          .map((item: FTPanelFeed) => item.channelName),
      ),
    ]
    const phaseB = [
      ...new Set(
        panelFeeds
          .filter((item: FTPanelFeed) => item.phase === 'Phase 2')
          .map((item: FTPanelFeed) => item.channelName),
      ),
    ]
    const phaseC = [
      ...new Set(
        panelFeeds
          .filter((item: FTPanelFeed) => item.phase === 'Phase 3')
          .map((item: FTPanelFeed) => item.channelName),
      ),
    ]

    const paneFeedFields = [
      {
        label: 'CT Size (mm)',
        value: ctSizes.join(', ') || '--',
        editable: false,
      },
      {
        label: 'Phase A - CT number(s) used',
        value: phaseA.length ? phaseA.join(', ') : '--',
        editable: false,
      },
      {
        label: 'Phase B  - CT number(s) used',
        value: phaseB.length ? phaseB.join(', ') : '--',
        editable: false,
      },
      {
        label: 'Phase C  - CT number(s) used',
        value: phaseC.length ? phaseC.join(', ') : '--',
        editable: false,
      },
      {
        label: 'Meter MAC',
        value: hardwareIds.join(', ') || '--',
        editable: false,
      },
    ]
    return (
      <>
        <PanelHeading>Panel Feed</PanelHeading>
        <VerticalTable.Basic fields={paneFeedFields} columned={false} />
      </>
    )
  }

  const renderPanelFields = () => {
    panel = panelsById[panelId]
    const parentPanelName =
      panel?.parentPanelId ?
        panelsById[panel.parentPanelId]?.name
      : panel?.parentPanelName
    if (!panel) return null
    const panelFields = [
      {
        label: 'Panel Name',
        value: panel.name || '--',
        editable: false,
      },
      {
        label: 'Panel Description',
        value: panel.description || '--',
        editable: false,
      },
      {
        label: 'Panel Location',
        value: panel.location || '--',
        editable: false,
      },
      {
        label: 'Panel Type',
        value: (panel.type && fieldNameMap.get(panel.type)) || '--',
        editable: false,
      },
      {
        label: 'Panel Voltage',
        value: (panel.voltage && fieldNameMap.get(panel.voltage)) || '--',
        editable: false,
      },
      {
        label: 'Amperage',
        value: panel.amperage || '--',
        editable: false,
      },
      {
        label: 'Power Source',
        value: panel.powerSourceLevel || '--',
        editable: false,
      },
      {
        label: 'Upstream Panel',
        value: parentPanelName || '--',
        editable: false,
      },
      {
        label: 'Auditor',
        value: panel.auditorName || '--',
        editable: false,
      },
      {
        label: 'Survey Date',
        value:
          panel?.auditDate ? moment(panel.auditDate).format('DD-MMM-YY') : '--',
        editable: false,
      },
      {
        label: 'Creation Date',
        value:
          panel?.created ? moment(panel.created).format('DD-MMM-YY') : '--',
        editable: false,
      },
      {
        label: 'Last Update',
        value:
          panel?.modified ? moment(panel.modified).format('DD-MMM-YY') : '--',
        editable: false,
      },
      {
        label: 'Photos',
        value: renderViewPhotos(),
        editable: false,
      },
    ]
    return (
      <Wrapper>
        <PanelHeading>Panel Info</PanelHeading>
        <VerticalTable.Basic
          fields={panelFields}
          columned
          className='panel-info'
        />
        {panel.panelFeed &&
          panel.panelFeed.length > 0 &&
          renderPanelFeed(panel.panelFeed)}
      </Wrapper>
    )
  }

  const onUpdatePanelId = (event: FTFormFieldEvent) => {
    const value = getValueFromEvent(event)
    actions.resetPanelCircuits()
    if (value && value !== panelId) {
      history.push(url.split('/').slice(0, -1).concat(value).join('/'))

      // to fetch the panel circuits whenever the panelId changes
      firstLoad.current = true
    }
  }

  const renderPanelsDropdown = () => {
    const makePanelListSelectorItem = (panelData: FTPanel) => {
      const { id, name } = panelData
      return {
        id,
        name,
        renderFunc: () => (
          <PanelSelectorItem
            onClickEdit={() => {}}
            onClickDelete={() => {}}
            panel={panelData}
            readOnly
          />
        ),
      }
    }

    const panelSelectorItems = panels.map((panelData) =>
      makePanelListSelectorItem(panelData),
    )
    const selectedPanelItem =
      panelId && panelsById[panelId] ?
        makePanelListSelectorItem(panelsById[panelId])
      : panelSelectorItems[0]
    return (
      <PanelDropdownContainer>
        <StyledPanelSelector
          items={panelSelectorItems}
          selectedItem={selectedPanelItem}
          updateValue={onUpdatePanelId}
          disabled={inEditMode}
          unsettable={false}
          notSetItemText='--'
          notSetItemValue=''
          notSetLabelText='--'
        />
      </PanelDropdownContainer>
    )
  }

  const handleAddPanelSuccess = () => {
    history.push(`${url}/edit`)
    if (panelId) {
      const objectId = `objectId=${panelId}/Location&objectId=${panelId}/Configuration&objectId=${panelId}/Box&objectId=${panelId}/Schedule&objectId=${panelId}/BreakerTypes
        `
      actions.fetchPanelPhotos({ objectId })
    }
  }

  const getActionPaneActions = () =>
    !inCreateMode && !inEditMode ?
      [
        // TODO: Enable this when needed
        // {
        //   href: '',
        //   external: true,
        //   label: 'Download Meter Data',
        // },
        {
          href: '',
          external: false,
          label: 'Edit Panel Details',
          onClick: (event: Event) => {
            event.preventDefault()
            if (panelPhotos.length > 0) {
              const fileIds = panelPhotos.map((item: { id: string }) => item.id)
              actions.fetchPhotosDownloadUrl({ fileIds })
            }
            actions.showModalPanelForm3({
              siteId,
              panelListEntity: panelEntity,
              panelById: panelsById[panelId],
              circuitsById,
              meterIds: panelsById[panelId]?.meterIds,
              updateMode: true,
              closeModal: actions.hideModal,
              handleSuccess: handleAddPanelSuccess,
            })
          },
        },
        {
          href: '',
          external: false,
          label: 'Delete Panel',
          onClick: (event: Event) => {
            event.preventDefault()
            actions.showConfirmModal({
              modalWidth: '418px',
              onPrimaryAction: undefined,
              onSecondaryAction: () => {
                actions.deletePanel({
                  id: panelId,
                  name: panelsById[panelId].name,
                })
              },
              primaryActionText: 'No',
              renderBody: () =>
                renderDeletePanelConfirm(panelsById[panelId].name),
              secondaryActionText: 'Yes',
            })
          },
        },
      ]
    : []

  const renderMain = () => {
    const circuitsObjectById =
      inCreateMode ?
        getCreatedCircuits(panelEntity.meta, isNumbered)
      : circuitsById

    const circuitsObject = structuredClone(circuitsObjectById)
    if (!isNumbered) {
      Object.keys(circuitsObject).forEach((key) => {
        const circuit = circuitsObject[key]
        if (circuit.switchLabel && circuit.switchLabel.trim() !== '') {
          circuit.breakerNumber = circuit.switchLabel
        }
        circuitsObject[key] = circuit
      })
    }
    let sortedCircuits =
      isNumbered === true && Object.keys(circuitsObjectById).length > 0 ?
        getSortedCircuits(Object.values(circuitsObject))
      : Object.values(circuitsObject)
    const circuitsOrder = sortedCircuits.map((circuit) => circuit.id)
    if (!isNumbered) {
      sortedCircuits = sortedCircuits.map((circuit) => ({
        ...circuit,
        breakerNumber:
          circuit.switchLabel ? circuit.switchLabel : circuit.breakerNumber,
      }))
    }

    let leftCircuits = sortedCircuits.filter(
      (circuit) => circuit.sideBreakerOrder === 'LEFT',
    )
    let rightCircuits = sortedCircuits.filter(
      (circuit) => circuit.sideBreakerOrder === 'RIGHT',
    )

    if (leftCircuits.length + rightCircuits.length < sortedCircuits.length) {
      const leftSideType = findArrayType(
        leftCircuits.map((circuit) => getNumericPart(circuit.breakerNumber)),
      )
      const rightSideType = findArrayType(
        rightCircuits.map((circuit) => getNumericPart(circuit.breakerNumber)),
      )

      if (leftSideType === 'EVEN' || rightSideType === 'ODD') {
        leftCircuits = sortedCircuits.filter(
          (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 0,
        )
        rightCircuits = sortedCircuits.filter(
          (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 1,
        )
      }
      if (leftSideType === 'ODD' || rightSideType === 'EVEN') {
        leftCircuits = sortedCircuits.filter(
          (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 1,
        )
        rightCircuits = sortedCircuits.filter(
          (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 0,
        )
      }

      if (leftSideType === 'CONSECUTIVE') {
        // half the circuits to left and half to right
        const halfLength = Math.floor(sortedCircuits.length / 2)
        leftCircuits = sortedCircuits.slice(0, halfLength)
        rightCircuits = sortedCircuits.slice(halfLength)
      }
    }

    // If the circuits don't have the mandatory fields.
    // TODO: How to handle Alphanumeric breakerNumber?
    if (
      sortedCircuits.length > 0 &&
      leftCircuits.length === 0 &&
      rightCircuits.length === 0
    ) {
      leftCircuits = sortedCircuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 1,
      )
      rightCircuits = sortedCircuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 0,
      )
    }
    if (!isNumbered) {
      rightCircuits = sortedCircuits
    }

    leftCircuits.forEach((circuit) => {
      circuitsObject[circuit.id] = { ...circuit, sideBreakerOrder: 'LEFT' }
    })

    rightCircuits.forEach((circuit) => {
      circuitsObject[circuit.id] = { ...circuit, sideBreakerOrder: 'RIGHT' }
    })

    const renderFunctions = {
      create: {
        numbered: renderCreatePanelConfirm,
        nonNumbered: renderCreateSwitchBoardConfirm,
      },
      update: {
        numbered: renderUpdateCircuitsConfirm,
        nonNumbered: renderUpdateSwitchesConfirm,
      },
    }

    const mode = inCreateMode ? 'create' : 'update'
    const type = isNumbered ? 'numbered' : 'nonNumbered'

    const renderCircuitDetailsForNumberedPanels = () =>
      (inEditMode && !circuitsLoading && circuits) || inCreateMode ?
        <FormikForm
          goBack={() => {
            actions.resetPanel()
            history.push(
              inCreateMode ? url.split('/').slice(0, -1).join('/') : url,
            )
          }}
          isNumbered={isNumbered}
          inCreateMode={inCreateMode && !addedPanelId}
          panels={panels}
          isUpdateSuccess={circuitsUpdatedSuccessfully}
          siteId={siteId}
          panelId={panelId}
          circuitsLoading={circuitsLoading}
          circuitsById={circuitsObject}
          circuitsOrder={circuitsOrder}
          buildingSystems={buildingSystems}
          buildingSystemsById={buildingSystemsById}
          showConfirmModal={actions.showConfirmModal}
          setErrorMessage={setError}
          deleteCircuitAction={actions.deletePanelCircuits}
          submitAction={(updatedCircuits, deletedCircuitsIds = []) => {
            actions.showConfirmModal({
              modalWidth: '418px',
              onPrimaryAction: undefined,
              onSecondaryAction: () => {
                if (deletedCircuitsIds && deletedCircuitsIds.length) {
                  actions.deletePanelCircuits({
                    circuitIds: deletedCircuitsIds,
                  })
                }
                actions.updatePanelCircuits({
                  panelId,
                  circuits: updatedCircuits.map((circuit) => ({
                    ...circuit,
                    siteId,
                  })),
                  fromPanelModal: !!tempPanelObject.fromPanelModal,
                  panelName: panelsById[panelId].name,
                  isNumbered,
                })
                actions.resetPanel()
                setIsPanelFlagUpdated(true)
              },
              primaryActionText: 'No',
              renderBody: renderFunctions[mode][type],
              secondaryActionText: 'Yes',
            })
          }}
        />
      : <PanelCircuitsTable
          leftCircuits={leftCircuits}
          rightCircuits={rightCircuits}
          isNumbered={isNumbered}
          circuitsLoading={circuitsLoading}
          columns={readOnlyColumns}
        />

    return (
      <>
        <Breadcrumbs items={breadcrumbs} />
        <Title>Panel Details</Title>
        {panelsLoading ?
          <Spinner size='medium' />
        : <div>
            {!!panels?.length && !inCreateMode && renderPanelsDropdown()}
            {renderPanelFields()}
          </div>
        }
        <CircuitSectionStyled>
          {isNumbered !== undefined && (
            <HeaderStyled>
              <SectionTitleStyled>
                {isNumbered ? 'Circuit Breakers' : 'Switch Board'}
              </SectionTitleStyled>
              {!inEditMode && !inCreateMode && (
                <EditActionStyled>
                  <EditNoteIconStyled />
                  <Link to={`${url}/edit`}>Edit</Link>
                </EditActionStyled>
              )}
            </HeaderStyled>
          )}
          <ErrorMessage
            message={circuitsDeleteError || circuitsUpdateError || error}
            collapseWhenEmpty
          />
          {renderCircuitDetailsForNumberedPanels()}
        </CircuitSectionStyled>
      </>
    )
  }

  React.useEffect(
    () => () => {
      actions.resetPanel()
    },
    [],
  )
  return (
    <ActionPaneView renderMain={renderMain} actions={getActionPaneActions()} />
  )
}

const mapStateToProps = (state) => {
  const panelEntity = selectPanelListEntity(state)
  const panelCircuitsEntity = selectPanelCircuitsEntity(state)
  // To get site name for the breadcrumbs
  const siteEntity = selectSiteEntity(state)
  // To get customer name for the breadcrumbs
  const customerEntity = selectCustomersById(state)
  const panelMetaData = selectPanelMetaData(state)
  const buildingSystemsEntity = selectors.selectListEntity(state)
  const buildingSystemsById = selectors.selectById(state)
  const tempPanelObject = selectTempMeta(state)
  const {
    allItems: panels,
    allById: panelsById,
    meta: {
      allLoading: panelsLoading,
      addedPanelId,
      addLoaded,
      deleteLoaded: panelDeleted,
      deleteLoading: panelDeleting,
    },
  } = panelEntity
  const {
    items: circuits,
    byId: circuitsById,
    meta: {
      loading: circuitsLoading,
      updateLoading: circuitsUpdating,
      updateError: circuitsUpdateError,
      deleteError: circuitsDeleteError,
      deleteLoading: circuitsDeleting,
    },
  } = panelCircuitsEntity
  const { byId: siteById } = siteEntity
  const { items: buildingSystems } = buildingSystemsEntity
  return {
    addedPanelId,
    addLoaded,
    panels,
    panelsById,
    panelEntity,
    panelDeleted,
    panelDeleting,
    buildingSystems,
    buildingSystemsById,
    panelsLoading,
    circuits,
    circuitsById,
    circuitsLoading,
    circuitsUpdating,
    circuitsUpdateError,
    circuitsDeleteError,
    circuitsDeleting,
    customerEntity,
    siteById,
    tempPanelObject,
    panelMetaData,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(panelCircuitActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(buildingSystemsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(panelMetaDataActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PanelDetailsPage),
)
