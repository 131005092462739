import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import EditableItem from './EditableItem'
import ErrorMessage from './ErrorMessage'
import Modal from './Modal'
import Spinner from './Spinner'
import { actions as modalActions } from '../ducks/modal'

const CloseButton = styled.button`
  && {
    background: transparent;
    border: none;
    width: 10px;
    height: 25px;
    padding: 0;
    margin: 0;
    font-size: 20px;
    margin-bottom: 10px;
  }
`

const ModalBody = styled.div`
  & input {
    font-family: 'Avenir Next';
    font-size: 14px;
    outline: none;
  }
`
const Styles = styled.div`
  .Modal .box {
    width: 400px;
    padding: 16px;
    box-sizing: content-box;
  }
  .Spinner.loader {
    border-width: 0.5em;
    width: 15px;
    height: 15px;
    margin-top: 6px;
    margin-right: 9px;
  }

  & button {
    border: 1px solid #dadada;
    background-color: #f5f5f5;
    height: 36px;
    border-radius: 6px;
    text-transform: uppercase;
    padding: 9px 44px;
    font-weight: 600;
    text-align: center;
    color: #7f7f7f;
    font-size: 14px;
    width: 145px;
    cursor: pointer;
    user-select: none;
  }

  & button[disabled] {
    cursor: default;
  }

  & button.primary {
    background-color: #0194d7;
    border-color: #0194d7;
    color: #fff;
  }

  & button.primary[disabled] {
    background-color: #a2deff;
    border-color: #a2deff;
  }

  & button + button {
    margin-left: 40px;
  }
`
const Title = styled.div`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 12px;

  text-transform: capitalize;

  position: relative;

  & .Spinner.loader {
    position: absolute;
    left: 105px;
    top: -5px;
    border-width: 0.4em;
    margin: 0;
  }

  & ${CloseButton} {
    float: right;
  }
`
const ListBox = styled.div`
  border-radius: 3px;
  height: ${({ isConfirming }) => (isConfirming ? '256px' : '444px')};
  width: 400px;
  border: 1px solid #c6c6c6;
  overflow-x: hidden;
  overflow-y: ${({ isConfirming }) => (isConfirming ? 'auto' : 'scroll')};
`
const AddBox = styled.div`
  ${ListBox} + & {
    margin-top: 16px;
  }

  & input {
    width: 325px;
    margin-left: 11px;
    height: 34px;
    border: none;
  }

  height: 36px;
  border-radius: 3px;
  width: 400px;
  border: 1px solid #c6c6c6;
  line-height: 36px;

  & .Spinner {
    float: right;
  }
`
const AddLink = styled.div`
  float: right;
  padding-right: 11px;
  color: #337ab7;
  cursor: pointer;
  user-select: none;
`
const ConfirmDelete = styled.div`
  text-align: center;
  margin-top: 30px;
  & div:last-child {
    margin-top: 30px;
  }
`
const DeleteEntityName = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #4a4a4a;
`
const AddEntityPrompt = styled.div`
  padding-left: 11px;
`
export type FTProps = {
  siteId: string
  entity: Record<string, any>
  // TODO: Put the correct type here
  onAddEntity: (...args: Array<any>) => any
  onUpdateEntity: (...args: Array<any>) => any
  onDeleteEntity: (...args: Array<any>) => any
  onSelectEntity: (...args: Array<any>) => any
  singularName: string
  // The singular name of the entity
  pluralName?: string
  // The plural name of the entity
  parentPluralName: string
  // The plural name of the parent entity
  addEntityText?: string
  actions: any
}
type FTState = {
  isConfirming: boolean
  entityToDelete:
    | {
        id: string
        name: string
      }
    | null
    | undefined
}

class Component extends PureComponent<FTProps, FTState> {
  static defaultProps = {
    addEntityText: '',
    pluralName: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      isConfirming: false,
      entityToDelete: null,
    }
  }

  componentDidUpdate(previousProps) {
    const {
      entity: { meta },
    } = this.props
    const {
      entity: { meta: previousMeta },
    } = previousProps
    const { deleteLoading } = meta
    const { deleteLoading: previousDeleteLoading } = previousMeta

    if (previousDeleteLoading === true && deleteLoading === false) {
      this.setState((prev) => ({
        ...prev,
        entityToDelete: null,
        isConfirming: false,
      }))
    }
  }

  onEntitySelect = (id) => {
    const { actions, onSelectEntity } = this.props
    onSelectEntity(id)
    actions.hideModal()
  }

  editEntity = (id, name) => {
    const { onUpdateEntity } = this.props

    if (id && name) {
      onUpdateEntity({
        id,
        name,
      })
    }
  }

  deleteEntity = () => {
    const { onDeleteEntity } = this.props
    const { entityToDelete } = this.state

    if (entityToDelete) {
      const { id } = entityToDelete
      onDeleteEntity({
        id,
      })
    }
  }

  showConfirmDelete = (entityToDelete) => {
    this.setState((prev) => ({ ...prev, entityToDelete, isConfirming: true }))
  }

  cancelDelete = () => {
    this.setState((prev) => ({
      ...prev,
      entityToDelete: null,
      isConfirming: false,
    }))
  }

  addEntity = (name) => {
    const { onAddEntity, siteId } = this.props
    onAddEntity({
      siteId,
      name,
    })
  }

  renderConfirmDelete = () => {
    const {
      entity: {
        meta: { deleteLoading },
      },
      singularName,
      parentPluralName,
    } = this.props
    const { entityToDelete } = this.state
    return (
      <ConfirmDelete>
        <div>
          {'Are you sure you want to delete this '}
          {singularName}?
        </div>
        {entityToDelete && (
          <DeleteEntityName>{entityToDelete.name}</DeleteEntityName>
        )}
        <div>
          {'All '}
          {parentPluralName}
          {' tagged with this '}
          {singularName}
          {' will be changed to "Unassigned".'}
        </div>
        <div>
          <button
            type='button'
            onClick={this.deleteEntity}
            disabled={deleteLoading}
          >
            Yes
          </button>
          <button
            type='button'
            className='primary'
            onClick={this.cancelDelete}
            disabled={deleteLoading}
          >
            No
          </button>
        </div>
      </ConfirmDelete>
    )
  }

  render() {
    const {
      actions,
      entity: { meta, items },
      singularName,
      pluralName,
      addEntityText,
    } = this.props
    const { addLoading, deleteLoading, updates, loading, error } = meta
    const { isConfirming, entityToDelete } = this.state
    const addPrompt = addEntityText || `Add a ${singularName} in the box below.`
    return (
      <Styles>
        <Modal
          isConfirming={isConfirming}
          renderBody={() => (
            <ModalBody>
              <Title>
                {'Edit '}
                {pluralName || `${singularName}s`}
                {deleteLoading && <Spinner size='tiny' />}
                <CloseButton
                  onClick={() => {
                    actions.hideModal()
                  }}
                  className='close-btn'
                >
                  <span className='ion-android-close' />
                </CloseButton>
              </Title>
              {error && <ErrorMessage message={error} />}
              <ListBox isConfirming={isConfirming}>
                {!isConfirming &&
                  items.length > 0 &&
                  items.map((entity) => (
                    <EditableItem
                      id={entity.id}
                      value={entity.name}
                      key={entity.id}
                      editLoading={updates.includes(entity.id)}
                      onClick={() => {
                        this.onEntitySelect(entity.id)
                      }}
                      onConfirmDelete={() => {
                        this.showConfirmDelete(entity)
                      }}
                      onEdit={(value: string) =>
                        this.editEntity(entity.id, value)
                      }
                    />
                  ))}
                {!loading && !isConfirming && items.length < 1 && (
                  <AddEntityPrompt>{addPrompt}</AddEntityPrompt>
                )}
                {isConfirming && entityToDelete && this.renderConfirmDelete()}
              </ListBox>
              {!isConfirming && (
                <AddBox>
                  <input
                    placeholder={`Type the ${singularName} name and click +Add`}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && e.target.value.length > 0) {
                        this.addEntity(e.target.value)
                        e.target.value = ''
                      }
                    }}
                  />
                  {!addLoading && (
                    <AddLink
                      onClick={({ target }) => {
                        if (target.previousElementSibling) {
                          const { previousElementSibling } = target
                          const { value } = previousElementSibling

                          if (value.length > 0) {
                            this.addEntity(value)
                            previousElementSibling.value = ''
                          }
                        }
                      }}
                    >
                      + Add
                    </AddLink>
                  )}
                  {addLoading && <Spinner size='tiny' />}
                </AddBox>
              )}
            </ModalBody>
          )}
        />
      </Styles>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(modalActions, dispatch),
})

export default connect(
  (state, props) => ({
    entity: props.entitySelector(state),
  }),
  mapDispatchToProps,
)(Component)
