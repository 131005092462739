import styled from 'styled-components'

import {
  ReactTableTopSectionStyles,
  ReactTableWrapperStyles,
  TdInnerStyled,
  ThInnerStyled,
} from '../../../components/RedaptiveReactTable7'
import { colors } from '../../../utils/themes'

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 30px;
  position: absolute;
  top: 10px;
`

export const Wrapper = styled.section`
  .TabPane-item {
    color: ${colors.blue15};
  }
`

export const MeterWrapper = styled.section`
  width: 70%;
`

export const Label = styled.label`
  font-weight: 600;
  padding-left: 10px;
  cursor: pointer;
`

export const RadioGroup = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  input {
    margin: 0;
  }
  label {
    margin-right: 20px;
  }
`

export const TableWrapperStyled = styled.div`
  position: relative;
  button[type='redaptiveTransparent'] {
    position: absolute;
    right: 420px;
  }
  .refresh-table {
    position: absolute;
    right: 540px !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  tr {
    &:nth-child(odd) {
      background-color: ${colors.gray9};
    }
    &:nth-child(even) {
      background-color: ${colors.white};
    }
  }
  margin-top: 50px;
  td {
    padding: 10px 0;
    &:first-child {
      > ${TdInnerStyled} {
        padding-left: 10px;
      }
    }
  }
  td {
    background-color: inherit;
    border: none;
  }
  thead {
    border: none;
  }
  th {
    &:first-child {
      ${ThInnerStyled} {
        padding-left: 0px;
      }
    }
    background-color: white;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: ${colors.gray};
    font-family: 'Avenir Next';
    input {
      width: 65%;
      padding-left: 10px !important;
      margin: 0;
    }
    .material-icons {
      display: none;
    }
  }
  ${ReactTableTopSectionStyles} {
    padding-bottom: 20px;
    border-bottom: 1px solid ${colors.gray3};
    margin: 0;
  }
  ${ReactTableWrapperStyles} {
    margin-top: 30px;
    overflow-x: scroll;
    border: none;
  }
`

export const InputLabel = styled.label`
  color: ${colors.gray2};
  font-weight: 600;
`

export const Input = styled.input`
  border: 1px solid ${colors.gray8};
  border-radius: 3px;
  height: 36px;
  width: 300px;
  margin-top: 20px;
  padding-left: 10px;
`

export const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 20px;
`
